import { Navigate, To, useLocation } from 'react-router-dom'
import NotFound from '../pages/NotFound'
import PageLoading from '../components/loading/PageLoading'
import React from 'react'
import Role from '../types/enums/Role'
import routes from '../routes'
import { useUserInfo } from '../custom-hooks/useFetchUserInfoQuery'
import { areEqualsUsingLocaleCompare } from '../utils/stringUtils'

interface AuthenticatedRouteProps {
  Component: React.JSX.Element
  allowedRoles?: Role[]
  missingRoleRedirect?: To
}

const AuthenticatedRoute = ({
  Component,
  allowedRoles,
  missingRoleRedirect: forbiddenRedirect
}: AuthenticatedRouteProps) => {
  const location = useLocation()
  const { data: userDetails, isLoading, error } = useUserInfo()
  const isAuthenticatedUser = userDetails?.id != null

  // Loading
  if (isLoading) return <PageLoading />

  // Error
  if (error) return <Navigate to={routes.error} />

  // Unauthenticated
  if (!isAuthenticatedUser) return <Navigate to={routes.login} replace />

  // Kriya User
  if (isAuthenticatedUser && userDetails.roles.some((userRole) => userRole === Role.KriyaUser)) {
    // without merchant details -> nagivate to merchant selection
    if (userDetails.marketplace == null && !areEqualsUsingLocaleCompare(location.pathname, routes.impersonate))
      return <Navigate to={routes.impersonate} replace />

    // with selected merchant -> show the component
    return Component
  }

  // Non-Kriya User with missing roles -> forbidden -> redirect (if exists) or Not Found
  if (isAuthenticatedUser && allowedRoles && !userDetails.roles.some((userRole) => allowedRoles.includes(userRole)))
    return forbiddenRedirect ? <Navigate to={forbiddenRedirect} replace /> : <NotFound />

  // Non-Kriya User with sufficient permissions -> show the component
  return Component
}

export default AuthenticatedRoute
