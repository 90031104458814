interface IUnleashApiKeyMapping {
  [Key: string]: string
}

export const stagingUnleashApiKey = 'default:development.fcecba305575cac64d4844bc201e7929dc5bbc8e6e445d76228c8263'
export const productionUnleashApiKey = 'default:production.ce0be602976508330ae073fa1ad4cd53e21ef4403a33622482063e5c'

const keyMapping: IUnleashApiKeyMapping = {
  'merchant.kriya.co': productionUnleashApiKey,
  'merchant.kriya.dev': stagingUnleashApiKey,
  'localhost:3000': stagingUnleashApiKey
}

const unleashApiKey = (currentUrl: string): string => {
  const host = new URL(currentUrl).host
  return keyMapping[host]
}

export default unleashApiKey
