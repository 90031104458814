import createTheme, { Theme } from '@mui/material/styles/createTheme'

import { Components } from '@mui/material/styles'

const font = "'Matter', 'Roboto Condensed', sans-serif"
const grey10 = '#FAFAFA'
const grey50 = '#F5F5F5'
const textGrey90 = '#C2C2C2'
const textGrey100Real = '#F0F0F0'
const textGrey100 = '#3C3C3C'
const textGrey200 = '#DEDEDE'
const textGrey400 = '#979797'
const textGrey500 = '#818181'
const textGrey600 = '#606060'
const textGrey700 = textGrey100
const textGrey900 = '#090909'
const inputText = textGrey900
const primaryMain = '#00283C'
const primary25 = 'rgba(0, 40, 60, 0.25)'
const errorColor = '#DC3545'
const backgroundGrey100 = '#F0F0F0'
const colorOnDisabled = textGrey90
const putty = '#FFF5E3'
const uploadBorderColor = textGrey200
const success = 'rgba(25, 135, 84, 1)'
const success30 = 'rgba(25, 135, 84, 0.08)'
const successUi = 'rgba(0, 124, 24, 1)'
const info = '#073DC9'
let theme = createTheme({
  spacing: 2, //Add every spacing as a multiple of 2. Eg: If you have to set padding: 8px, write it as theme.spacing(4) i.e. 4*2 = 8px
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },
  palette: {
    primary: {
      main: primaryMain
    },
    common: {
      inputText,
      buttonBackgroundHover: '#32627F',
      buttonFocusStroke: '#00CF3B',
      backgroundActive: '#3F7090',
      buttonColorOnDisabled: colorOnDisabled,
      textBoxHover: grey10,
      navItem: putty,
      putty,
      uploadBorderColor,
      grey10,
      grey50,
      backgroundGrey100,
      textGrey100Real,
      textGrey100,
      textGrey200,
      textGrey400,
      textGrey500,
      textGrey600,
      textGrey700,
      textGrey900,
      success,
      success30,
      successUi,
      colorOnDisabled,
      primary25,
      info,
      panels: {
        error: {
          color: 'rgba(220, 53, 69, 1)',
          background: 'rgba(220, 53, 69, 0.08)'
        },
        warning: {
          color: 'rgba(134, 109, 36, 1)',
          background: 'rgba(134, 109, 36, 0.08)'
        },
        information: {
          color: 'rgba(7, 61, 201, 1)',
          background: 'rgba(7, 61, 201, 0.08)'
        }
      },
      tags: {
        mandatory: {
          color: 'rgba(7, 61, 201, 1)',
          background: 'rgba(7, 61, 201, 0.08)',
          border: 'rgba(7, 61, 201, 0.3)'
        },
        optional: {
          color: 'rgba(60,60,60, 1)',
          background: `rgba(60, 60, 60, 0.08)`,
          border: `rgba(60, 60, 60, 0.3)`
        }
      }
    },
    error: {
      main: errorColor
    }
  },
  typography: {
    fontFamily: font,
    h0: {
      fontSize: '32px',
      fontWeight: '500',
      lineHeight: '40px',
      color: inputText,
      fontFamily: font
    },
    h1: {
      fontSize: '28px',
      fontWeight: '500',
      lineHeight: '28px',
      color: inputText
    },
    h2: {
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '22px',
      color: inputText
    },
    h3: {
      fontFamily: font,
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '28px',
      color: inputText
    },
    h4: {
      fontFamily: font,
      fontSize: '26px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '36px'
    },
    h5: {
      fontFamily: font,
      fontSize: '32px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '40px',
      color: textGrey900
    },
    h6: {
      fontFamily: font,
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '28px',
      color: textGrey900
    },
    inputLabel: {
      fontFamily: font,
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '18px',
      color: inputText
    },
    inputText: {
      fontFamily: font,
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px'
    },
    helperText: {
      fontFamily: font,
      fontSize: '12px',
      fontWeight: '700',
      lineHeight: '16px',
      color: errorColor
    },
    helperText2: {
      fontFamily: font,
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '18px',
      color: errorColor
    },
    boldHelperText2: {
      fontFamily: font,
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '18px',
      color: errorColor,
      letterSpacing: '0.16px'
    },
    navBarText: {
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '20px',
      color: putty
    },
    subtitle1: {
      fontFamily: font,
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '20px',
      color: textGrey100
    },
    subtitle2: {
      fontFamily: font,
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '24px',
      color: textGrey100
    },
    caption: {
      fontFamily: font,
      fontSize: '10px',
      fontWeight: '600',
      lineHeight: '16px'
    },
    body1: {
      fontFamily: font,
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px'
    },
    body2: {
      color: textGrey100,
      fontFamily: font,
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px'
    },
    text1: {
      color: textGrey100,
      fontFamily: font,
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px'
    },
    text2: {
      color: textGrey100,
      fontFamily: font,
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px'
    },
    defaultTableHeader: {
      fontFamily: font,
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: '18px',
      color: inputText,
    },
    tableText: {
      fontFamily: font,
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '18px',
      color: inputText,
      letterSpacing: '0.16px'
    },
    supplementaryText: {
      fontFamily: font,
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '16px',
      color: textGrey700,
      letterSpacing: '0.32px'
    },
    linkButton2: {
      color: textGrey900,
      fontFamily: font,
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '24px',
      textDecorationLine: 'underline'
    },
    body3: {
      fontFamily: font,
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '28px'
    },
    label2: {
      fontFamily: font,
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: '0.16px'
    },
    label1: {
      fontFamily: font,
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      textDecorationLine: 'underline'
    }
  }
})

const components: Components<Theme> = {
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
        subtitle1: 'h6',
        subtitle2: 'h6',
        body1: 'p',
        body2: 'p',
        inherit: 'p'
      }
    }
  },
  MuiButton: {
    defaultProps: {
      disableTouchRipple: true,
      disableFocusRipple: true
    },
    styleOverrides: {
      root: {
        fontSize: theme.typography.inputText.fontSize,
        fontWeight: 600,
        textTransform: 'none',
        borderRadius: '100px',
        height: '48px',
        padding: theme.spacing(6, 12),
        ':hover': {
          background: theme.palette.common.buttonBackgroundHover,
          color: theme.palette.common.white
        },
        ':focus': {
          background: theme.palette.primary.main,
          boxShadow: `0 0 0 3px ${theme.palette.common.buttonFocusStroke}`,
          color: theme.palette.common.white
        },
        ':active': {
          background: theme.palette.common.backgroundActive,
          color: theme.palette.common.white
        },
        ':disabled': {
          color: theme.palette.common.buttonColorOnDisabled,
          background: theme.palette.common.backgroundGrey100,
          boxShadow: `0 0 0 3px ${theme.palette.common.backgroundGrey100}`
        }
      }
    }
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        'label + &': {
          marginTop: '4px'
        },
        '& .MuiInputBase-input': {
          padding: theme.spacing(6)
        },
        outline: 'none',
        backgroundColor: theme.palette.common.white,
        position: 'relative',
        borderRadius: '8px',
        fontSize: theme.typography.inputText.fontSize,
        lineHeight: theme.typography.inputText.lineHeight,
        width: '100%',
        ':focus': {
          borderColor: theme.palette.common.backgroundActive
        },
        ':hover': {
          backgroundColor: theme.palette.common.textBoxHover
        }
      }
    }
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        padding: theme.spacing(6),
        outline: 'none',
        backgroundColor: theme.palette.common.white,
        'label + &': {
          marginTop: '4px'
        }
      }
    }
  },

  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: theme.palette.primary.main,
        maxWidth: 250,
        ...theme.typography.inputLabel,
        color: theme.palette.common.white
      }
    }
  },
  MuiTableContainer: {
    styleOverrides: {
      root: {
        transition: 'none',
        boxShadow: 'none'
      }
    }
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        backgroundColor: putty,
        color: colorOnDisabled
      }
    }
  },
  MuiAutocomplete: {
    styleOverrides: {
      inputRoot: {
        '& .MuiAutocomplete-input': {
          padding: theme.spacing(2)
        }
      }
    }
  }
}

theme = createTheme(theme, {
  components
})
export default theme
