import React from 'react'
import { keyframes, styled } from '@mui/material/styles'
import { SpinnerImg } from '../../assets/storageAccountUploadLinks'

const rotate = keyframes`
from {
  transform: rotate(0deg);
}

to {
  transform: rotate(360deg);
}
`

const StyledSpan = styled('span')`
  display: flex;
  width: 40px;
  height: 40px;
  animation: ${rotate} 2s linear infinite;
`

const Spinner = () => {
  return (
    <StyledSpan id="spinner">
      <img src={SpinnerImg} alt="Spinner" />
    </StyledSpan>
  )
}

export default Spinner
