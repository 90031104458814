import { useNavigate } from 'react-router-dom'
import { apiClient } from '../api/apiClient'
import { useMutation } from 'react-query'
import routes from '../routes'

export const requestUserAccessInfo = async (email: string): Promise<number> => {
  const response = await apiClient.post<string>(`login/requestaccess`, {
    email: email
  })
  return response.status
}

export const useRequestAccessInfo = ({
  email,
  onSuccess,
  onError
}: {
  email: string
  onSuccess?: () => void
  onError?: () => void
}) => {
  const navigate = useNavigate()

  const requestUserAccessMutation = useMutation(
    async (): Promise<number> => {
      const data = await requestUserAccessInfo(email)
      return data
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        } else navigate(routes.success)
      },
      onError: () => {
        if (onError) {
          onError()
        } else {
          navigate(routes.error)
        }
      }
    }
  )

  return requestUserAccessMutation
}
