interface IAPIURLMapping {
  [Key: string]: string
}

const apiUrlMapping: IAPIURLMapping = {
  'merchant.kriya.co': 'https://api.kriya.co/merchant/',
  'merchant.kriya.dev': 'https://api.kriya.dev/merchant/',
  localhost: 'https://api.kriya.dev/merchant/'
}

const apiCompanyInfoUrlMapping: IAPIURLMapping = {
  'merchant.kriya.co': 'https://api.kriya.co/company-info/',
  'merchant.kriya.dev': 'https://api.kriya.dev/company-info/',
  localhost: 'https://api.kriya.dev/company-info/'
}

const apiFilesUrlMapping: IAPIURLMapping = {
  'merchant.kriya.co': 'https://api.kriya.co/files/',
  'merchant.kriya.dev': 'https://api.kriya.dev/files/',
  localhost: 'https://api.kriya.dev/files/'
}

const deploymentHost = (currentUrl: string): string => {
  return new URL(currentUrl).hostname
}

const apiBaseUrl = (currentUrl: string): string => {
  return apiUrlMapping[deploymentHost(currentUrl)]
}

const apiCompanyInfoBaseUrl = (currentUrl: string): string => {
  return apiCompanyInfoUrlMapping[deploymentHost(currentUrl)]
}

const apiFilesBaseUrl = (currentUrl: string): string => {
  return apiFilesUrlMapping[deploymentHost(currentUrl)]
}

const retry: number | boolean = deploymentHost(window.location.href) === 'localhost' ? false : 3

export { deploymentHost, retry, apiCompanyInfoBaseUrl, apiFilesBaseUrl }
export default apiBaseUrl
