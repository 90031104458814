import { useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import routes from '../routes'
import { apiFilesClient } from '../api/apiFilesClient'
import { UploadedFileInfo } from '../types/UploadedFileInfo'

export const filesUpload = async (
  files: File[],
  containerId: string,
  category?: string
): Promise<UploadedFileInfo[]> => {
  const result = await Promise.all(
    files.map(async (file) => {
      const formData = new FormData()
      formData.append(file.name, file)
      if (category) {
        formData.append('category', category)
      }
      const response = await apiFilesClient.post<string>(`/upload/${containerId}/${file.name}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        validateStatus: function (status) {
          return status < 500
        }
      })

      return {
        fileId: response.data,
        name: file.name,
        category: category,
        statusCode: response.status
      } as UploadedFileInfo
    })
  )
  return result
}

export const useFilesUpload = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) => {
  const navigate = useNavigate()

  return useMutation(
    async ({
      filesToUpload,
      containerId,
      category
    }: {
      filesToUpload: File[]
      containerId: string
      category?: string
    }): Promise<UploadedFileInfo[]> => {
      return await filesUpload(filesToUpload, containerId, category)
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
      },
      onError: () => {
        if (onError) {
          onError()
        } else {
          navigate(routes.error)
        }
      }
    }
  )
}
