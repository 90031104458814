import theme from '../../assets/theme'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import React, { ReactElement } from 'react'
import { FieldValues, Path, UseFormReturn } from 'react-hook-form'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Tooltip from '@mui/material/Tooltip'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

interface FormRowProps<T extends FieldValues> {
  label?: string
  formField?: Path<T>
  form?: UseFormReturn<T>
  component: ReactElement
  tooltip?: string
  mergeLabel?: boolean
  hideState?: boolean
}

/**
 * @deprecated This component should not be used - please use FormControl or FormRow instead
 */
const FormComponent = <T extends FieldValues>({
  label,
  formField,
  form,
  component,
  tooltip,
  mergeLabel,
  hideState
}: FormRowProps<T>) => {
  const formState = form && formField ? form.getFieldState(formField) : undefined
  return (
    <Grid container sx={{ alignItems: 'center', marginBottom: theme.spacing(8) }} data-cy={'row-component'}>
      {!mergeLabel && (
        <Grid item md={4}>
          <Typography variant={'body1'}>
            {label}
            {tooltip && (
              <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{tooltip}</div>}>
                <InfoOutlinedIcon fontSize="small" sx={{ verticalAlign: 'middle', paddingLeft: theme.spacing(2) }} />
              </Tooltip>
            )}
          </Typography>
        </Grid>
      )}
      <Grid item md={mergeLabel ? 11 : 7}>
        {component}
      </Grid>
      <Grid item md={1} sx={{ paddingLeft: theme.spacing(6) }}>
        {!hideState && !formState?.invalid && <CheckCircleOutlineIcon sx={{ color: theme.palette.success.main }} />}
        {!hideState && (formState?.isDirty || formState?.isTouched) && formState?.invalid && (
          <CancelOutlinedIcon sx={{ color: theme.palette.error.main }} />
        )}
      </Grid>
    </Grid>
  )
}

export default FormComponent
