import React from 'react'
import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'
import { ColorWithBackground } from '@mui/material/styles/createPalette'

const TagContainer = styled.span<{ color: string; backgroundColor: string; borderColor?: string }>`
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  border: 2px solid ${(props) => props.borderColor};
  flex-shrink: 1;
  border-radius: 5px;
  padding: 5px;
`

interface TagProps {
  colorOptions: ColorWithBackground
  text: string
}
const Tag = ({ colorOptions, text }: TagProps) => {
  return (
    <TagContainer
      color={colorOptions.color}
      backgroundColor={colorOptions.background}
      borderColor={colorOptions.border}
    >
      <Typography variant={'caption'}>{text}</Typography>
    </TagContainer>
  )
}

export default Tag
