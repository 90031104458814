import apiBaseUrl from './apiBaseUrl'
import axios, { AxiosRequestConfig } from 'axios'
import { setupInterceptorsTo } from './interceptor'

const validateStatus = (status: number): boolean => {
  return status >= 200 && status < 400
}

export const getAxiosConfigAuth = (baseUrl: string, withCredentials = true): AxiosRequestConfig => {
  return {
    validateStatus,
    withCredentials,
    baseURL: baseUrl
  }
}

const axiosConfig = getAxiosConfigAuth(apiBaseUrl(window.location.href))
export const apiClient = setupInterceptorsTo(axios.create(axiosConfig))
