import React, { ChangeEvent, useEffect, useState } from 'react'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Checkbox from '@mui/material/Checkbox'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Dayjs } from 'dayjs'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import ListItemText from '@mui/material/ListItemText'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import SearchIcon from '@mui/icons-material/Search'
import TextField from '@mui/material/TextField'
import content from '../content'
import useDebounce from '../../custom-hooks/useDebounce'
import { useOrderFilterInfo } from '../../custom-hooks/useFetchOrderFiltersQuery'

const orderFiltersContent = content.pages.orderList.filters

interface OrderFiltersProps {
  status: string[]
  paymentMethod: string[]
  dateFrom: Dayjs | null
  dateTo: Dayjs | null
  setKeyword: (value: string) => void
  setStatus: (value: string[]) => void
  setPaymentMethod: (value: string[]) => void
  setDateFrom: (value: Dayjs | null) => void
  setDateTo: (value: Dayjs | null) => void
}

const OrderListFilters = ({
  setKeyword,
  status,
  setStatus,
  paymentMethod,
  setPaymentMethod,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo
}: OrderFiltersProps) => {
  const { data, isLoading } = useOrderFilterInfo()
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  const handleKeywordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  useEffect(() => {
    if (debouncedSearchTerm !== undefined) {
      setKeyword(debouncedSearchTerm)
    }
  }, [debouncedSearchTerm, setKeyword])

  const handleChange = (event: SelectChangeEvent<typeof status>) => {
    const {
      target: { value }
    } = event
    setStatus(typeof value === 'string' ? value.split(',') : value)
  }

  const handlePaymentChange = (event: SelectChangeEvent<typeof paymentMethod>) => {
    const {
      target: { value }
    } = event
    setPaymentMethod(typeof value === 'string' ? value.split(',') : value)
  }

  return (
    <>
      <Grid container>
        <Grid item xs={6} md={4} marginBottom={20}>
          <TextField
            label={orderFiltersContent.search.label}
            placeholder={orderFiltersContent.search.placeholder}
            variant="outlined"
            fullWidth
            onChange={handleKeywordChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            id="search-company"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3} md={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>{orderFiltersContent.orderStatus.label}</InputLabel>
            <Select
              labelId="multiple-status-label"
              id="multiple-status"
              multiple
              displayEmpty
              value={status}
              onChange={handleChange}
              input={<OutlinedInput label={orderFiltersContent.orderStatus.label} />}
              renderValue={(selected) => selected.join(', ')}
              disabled={isLoading}
            >
              {data?.statuses?.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={status.includes(name)} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={3} md={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>{orderFiltersContent.paymentMethods.label}</InputLabel>
            <Select
              labelId="multiple-payment-method-label"
              id="multiple-payment-method"
              multiple
              displayEmpty
              value={paymentMethod}
              onChange={handlePaymentChange}
              input={<OutlinedInput label={orderFiltersContent.paymentMethods.label} />}
              renderValue={(selected) => selected.join(', ')}
              disabled={isLoading}
            >
              {data?.paymentMethods?.map((d) => (
                <MenuItem key={d} value={d}>
                  <Checkbox checked={paymentMethod.indexOf(d) > -1} />
                  <ListItemText primary={d} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid item xs={3} md={2}>
            <DatePicker
              label={orderFiltersContent.dateFrom.label}
              value={dateFrom}
              onChange={(newValue) => setDateFrom(newValue)}
              format="DD-MM-YYYY"
              data-testid="from-date"
              disableFuture
              maxDate={dateTo}
            />
          </Grid>

          <Grid item xs={3} md={2}>
            <DatePicker
              label={orderFiltersContent.dateTo.label}
              value={dateTo}
              data-cy="to-date"
              onChange={(newValue) => setDateTo(newValue)}
              format="DD-MM-YYYY"
              minDate={dateFrom}
              disableFuture
            />
          </Grid>
        </LocalizationProvider>
      </Grid>
    </>
  )
}

export default OrderListFilters
