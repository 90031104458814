import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'

const reactPlugin: ReactPlugin = new ReactPlugin()
let appInsights: ApplicationInsights

const telemetryInitializer = (envelope: ITelemetryItem) => {
  if (envelope.tags !== undefined) {
    envelope.tags['ai.cloud.role'] = 'FrontEnd.MarketplacePortal'
    envelope.tags['ai.cloud.roleInstance'] = 'FrontEnd.MarketplacePortal'
  }
}

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {
  const initialize = (instrumentationKey: string) => {
    if (!instrumentationKey) {
      throw new Error('Instrumentation key not provided')
    }

    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentationKey,
        extensions: [reactPlugin]
      }
    })

    appInsights.loadAppInsights()
    appInsights.addTelemetryInitializer(telemetryInitializer)
  }

  return { reactPlugin, initialize }
}

export const telemetryService = createTelemetryService()
export const getAppInsights = () => appInsights

export const trackAppInsightsEvent = (name: string, measurements: { [Key: string]: any }): void => {
  if (appInsights === undefined || appInsights.trackEvent === undefined) {
    return
  }

  appInsights.trackEvent({ name }, measurements)
}

export const trackAppInsightsException = (e: ErrorEvent): void => {
  if (appInsights === undefined || appInsights.trackEvent === undefined) {
    return
  }

  appInsights.trackException(e, {
    name: 'MarketplacePortalError',
    message: e.message,
    error: JSON.stringify(e.error),
    location: `${e.filename}:${e.lineno}:${e.colno}`,
    target: e.target
  })
}

export const trackError = (e: ErrorEvent): void => {
  trackAppInsightsException(e)
}
