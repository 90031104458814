import { useQuery } from 'react-query'
import { retry } from '../api/apiBaseUrl'
import { apiClient } from '../api/apiClient'
import { GetMerchantSpendingLimitResponse } from '../types/GetMerchantSpendingLimitResponse'

export const fetchMerchantSpendingLimitKey = 'fetch-merchant-spending-limit'

export const fetchMerchantSpendingLimit = async (): Promise<GetMerchantSpendingLimitResponse> => {
  const response = await apiClient.get<GetMerchantSpendingLimitResponse>('/marketplace/spendinglimit')
  return response.data
}

export const useFetchMerchantSpendingLimit = () => {
  const { data, isLoading } = useQuery<GetMerchantSpendingLimitResponse, Error>(
    [fetchMerchantSpendingLimitKey],
    () => fetchMerchantSpendingLimit(),
    { retry, refetchOnWindowFocus: false }
  )
  return { data, isLoading }
}
