import React from 'react'
import Card from '../components/PageCard'
import content from './content'
import EmailForm from '../components/EmailForm'

const componentContent = content.pages.login

const Login = () => {
  return (
    <Card title={componentContent.title} content={componentContent.description}>
      <EmailForm />
    </Card>
  )
}

export default Login
