export type DirectorVerificationStatusProps = {
  [key: string]: {
    display: string
    value: string
  }
}

export const directorVerificationStatus: DirectorVerificationStatusProps = {
  NotStarted: {
    display: 'Not started',
    value: 'NotStarted'
  },
  InProgress: {
    display: 'In progress',
    value: 'InProgress'
  },
  Approved: {
    display: 'Approved',
    value: 'Passed'
  },
  Rejected: {
    display: 'Rejected',
    value: 'Failed'
  },
  Pending: {
    display: 'Pending risk decision',
    value: 'PendingRiskDecision'
  },
  NotAvailable: {
    display: 'N/A',
    value: 'NA'
  }
}
