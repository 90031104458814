import Box from '@mui/material/Box'
import React from 'react'
import Typography from '@mui/material/Typography'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import { styled } from '@mui/material/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import theme from '../assets/theme'

const StyledAccordion = styled(Accordion)({
  backgroundColor: 'transparent',
  boxShadow: 'none'
})

interface FaqItem {
  description: string
  title: string
}

export interface GenericFaqItemsProps {
  maxWidth: string
  content: { title: string; items: FaqItem[] }
}

const GenericFaqItems = ({ content, maxWidth }: GenericFaqItemsProps) => {
  return (
    <Box id="faq" sx={{ maxWidth: maxWidth }} data-cy={'faq-items-container'}>
      <Typography
        variant="h1"
        sx={{
          paddingBottom: theme.spacing(15)
        }}
      >
        {content.title}
      </Typography>

      {content.items.map((r, index) => (
        <StyledAccordion key={`faqItem-${index}`}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
            <Typography variant={'h2'}>{r.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography whiteSpace={'pre-line'}>{r.description}</Typography>
          </AccordionDetails>
        </StyledAccordion>
      ))}
    </Box>
  )
}

export default GenericFaqItems
