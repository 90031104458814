import { OrderFiltersResponse } from '../types/orderFiltersResponse'
import { apiClient } from '../api/apiClient'
import { retry } from '../api/apiBaseUrl'
import { useQuery } from 'react-query'

export const fetchOrdersFiltersKey = 'fetch-order-filters'

export const fetchOrderFilterDetails = async (): Promise<OrderFiltersResponse> => {
  const response = await apiClient.get<OrderFiltersResponse>('orders/filters')
  return response.data
}

export const useOrderFilterInfo = () => {
  const { data, isLoading, error, isSuccess, isFetching } = useQuery<OrderFiltersResponse, Error>(
    [fetchOrdersFiltersKey],
    () => fetchOrderFilterDetails(),
    {
      retry,
      refetchOnWindowFocus: false
    }
  )

  return { data, isLoading, error, isSuccess, isFetching }
}
