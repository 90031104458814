import { buyerListRiskDecisions } from '../types/buyerRiskDecisions'
import { areEqualsUsingLocaleCompare } from './stringUtils'

export const isRiskSubmitted = (value?: string) =>
  areEqualsUsingLocaleCompare(buyerListRiskDecisions.Submitted.value, value || '')
export const isRiskInReview = (value?: string) =>
  areEqualsUsingLocaleCompare(buyerListRiskDecisions.InReview.value, value || '')
export const isRiskApproved = (value?: string) =>
  areEqualsUsingLocaleCompare(buyerListRiskDecisions.Approved.value, value || '')
export const isRiskRejected = (value?: string) =>
  areEqualsUsingLocaleCompare(buyerListRiskDecisions.Rejected.value, value || '')
export const isRiskOnHold = (value?: string) =>
  areEqualsUsingLocaleCompare(buyerListRiskDecisions.OnHold.value, value || '')

export function getRiskDecisionDescription(value?: string): string {
  switch (value) {
    case null:
    case undefined:
    case buyerListRiskDecisions.Submitted.value:
      return 'Submitted'
    case buyerListRiskDecisions.InReview.value:
      return 'In review'
    case buyerListRiskDecisions.OnHold.value:
      return 'On hold'
    case buyerListRiskDecisions.Approved.value:
      return 'Approved'
    case buyerListRiskDecisions.Rejected.value:
      return 'Rejected'
    default:
      return value
  }
}
