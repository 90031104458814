import React, { ChangeEvent, useEffect, useState } from 'react'
import theme from '../../assets/theme'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'

import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import styled from '@emotion/styled'
import content from '../content'
import { formatCurrency } from '../../utils/formatCurrency'
import { MainDiv, Container } from '../../components/dropzone/dropzoneStyles'
import ErrorText from '../../components/input/FormErrors'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import { useDropzone } from 'react-dropzone'
import Tag from '../../components/Tag'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import { useForm } from 'react-hook-form'
import { useDeliveryConfirmationEvidenceSubmission } from '../../custom-hooks/useDeliveryConfirmationEvidenceSubmission'
import { useFilesUpload } from '../../custom-hooks/useFilesUpload'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import routes from '../../routes'
import { deliveryConfirmationFileSize } from '../../consts'
import { useFetchDeliveryConfirmation } from '../../custom-hooks/useFetchOrderDeliveryConfirmation'
import { UploadedFileInfo } from '../../types/UploadedFileInfo'
import GenericFaqItems from '../../components/GenericFaqItems'
import { ArrowLeftIcon } from '@mui/x-date-pickers'
import Stack from '@mui/material/Stack'
import { replaceString } from '../../utils/stringReplace'
import { DropzoneRejectedFilesListComponent } from '../../components/dropzone/DropzoneRejectedFilesListComponent'
import { DeliveryConfirmationResponse } from '../../types/deliveryConfirmationResponse'
import { AxiosError } from 'axios'
import Box from '@mui/material/Box'
import Spinner from '../../components/loading/Spinner'

const FormContainer = styled.div`
  margin-bottom: 100px;
`

export const DeliveryConfirmationTitleContainer = styled.div`
  display: flex;
  align-items: start;
`

const NoteTextField = styled(TextField)`
  resize: none;
  border-color: ${theme.palette.common.uploadBorderColor};
  border-radius: 8px;
`
const CharacterCounter = styled.span`
  float: right;
`

const pageContent = content.pages.orderDeliveryConfirmation

export const DeliveryConfirmationUploadContainer = styled.div`
  border: 1px solid ${theme.palette.common.uploadBorderColor};
  padding: ${theme.spacing(6)};
  margin-bottom: ${theme.spacing(16)};
  border-radius: 8px;
`

const OrderDeliveryConfirmationPage = () => {
  const [isSubmissionLoading, setIsSubmissionLoading] = useState(false)
  const [invoiceFile, setInvoiceFile] = useState<File | null>(null)
  const [otherFiles, setOtherFiles] = useState<File[]>([])

  const [previouslyUploadedInvoiceFile, setPreviouslyUploadedInvoiceFile] = useState<UploadedFileInfo>()
  const [previouslyUploadedOtherFiles, setPreviouslyUploadedOtherFiles] = useState<UploadedFileInfo[]>([])

  const [errorMessage, setErrorMessage] = useState<string>('')
  const params = useParams()

  const orderId = params.orderId
  const { data } = useFetchDeliveryConfirmation({ orderId })

  const location = useLocation()
  let deliveryConfirmationData: DeliveryConfirmationResponse | undefined =
    location.state as DeliveryConfirmationResponse
  if (!deliveryConfirmationData) {
    deliveryConfirmationData = data
  }

  const [additionalNoteCharacterCount, setAdditionalNoteCharacterCount] = React.useState<string>('')

  const navigate = useNavigate()

  const { mutate: uploadFiles } = useFilesUpload({})

  const { mutate: submitDeliveryConfirmationEvidence } = useDeliveryConfirmationEvidenceSubmission({})

  const acceptedInvoiceFileTypes = '.pdf, .msg, .png, .jpeg, .tiff, .xlsx or .csv'
  const acceptedOtherFileTypes = '.pdf, .msg, .png, .jpeg or .tiff'

  const tableContent = [
    {
      title: pageContent.table.customerName,
      value: deliveryConfirmationData?.companyName
    },

    {
      title: pageContent.table.invoiceReferenceNumber,
      value: deliveryConfirmationData?.invoiceReferenceNumber
    },
    {
      title: pageContent.table.amount,
      value: deliveryConfirmationData?.amount
        ? formatCurrency(deliveryConfirmationData?.amount, deliveryConfirmationData?.currencyCode)
        : ''
    }
  ]

  const form = useForm<{ invoiceFile: File; otherFiles: File[]; note: string }>({
    mode: 'onChange',
    defaultValues: {
      note: '',
      otherFiles: []
    },
    shouldUnregister: false,
    reValidateMode: 'onChange'
  })

  const { register } = form

  const { handleSubmit } = form

  const {
    fileRejections: rejectedOtherFiles,
    getRootProps: getOtherRootProps,
    getInputProps: getOtherInputProps,
    isFocused: isOtherFocused,
    isDragActive: isOtherDragActive
  } = useDropzone({
    onDrop: (droppedFiles: File[]) => {
      setErrorMessage('')
      setOtherFiles([...otherFiles, ...droppedFiles])
    },
    accept: {
      'application/pdf': ['.pdf'],
      'application/vnd.ms-outlook': ['.msg'],
      'image/jpeg': ['.jpeg', '.png'],
      'image/tiff': ['.tiff']
    }
  })

  const {
    fileRejections: rejectedInvoiceFiles,
    getRootProps: getInvoiceRootProps,
    getInputProps: getInvoiceInputProps,
    isFocused: isInvoiceFocused,
    isDragActive: isInvoiceDragActive
  } = useDropzone({
    onDrop: (droppedFiles: File[]) => {
      setErrorMessage('')
      setInvoiceFile(droppedFiles[0])
    },
    accept: {
      'application/pdf': ['.pdf'],
      'image/jpeg': ['.jpeg', '.png'],
      'application/vnd.ms-outlook': ['.msg'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'text/csv': ['.csv'],
      'image/tiff': ['.tiff']
    },
    maxFiles: 1,
    multiple: false
  })

  const isFileValid = (file: File | Blob): boolean => {
    const fileSize = file.size
    const fileSizeMb = fileSize / (1024 * 1024)
    if (fileSizeMb > deliveryConfirmationFileSize) {
      setErrorMessage(content.filesUpload.fileTooBig)
      return false
    }
    if (fileSize < 1) {
      setErrorMessage(content.filesUpload.fileTooSmall)
      return false
    }
    return true
  }

  const submitEvidence = (
    orderId: string,
    invoice: UploadedFileInfo,
    otherFiles: UploadedFileInfo[],
    note: string,
    withRetry: boolean
  ) => {
    submitDeliveryConfirmationEvidence(
      {
        orderId,
        invoiceFileInfo: invoice,
        otherFilesInfo: otherFiles,
        note
      },
      {
        onSuccess: () => {
          setIsSubmissionLoading(false)
          navigate(routes.orders)
        },
        onError: (error: AxiosError) => {
          if (error.response?.status === 404 && withRetry) {
            setTimeout(() => {
              submitEvidence(orderId, invoice, otherFiles, note, false)
            }, 15000)
          } else {
            navigate(routes.error)
          }
        }
      }
    )
  }

  const onSubmit = ({ note }: { note: string }) => {
    if (isSubmissionLoading) {
      return
    }

    if (!invoiceFile && !previouslyUploadedInvoiceFile) {
      setErrorMessage(content.pages.orderDeliveryConfirmation.errors.noInvoiceUploaded)
      return
    }

    let filesToUpload = []
    if (invoiceFile) {
      filesToUpload.push(invoiceFile)
    }

    if (otherFiles) {
      filesToUpload = filesToUpload.concat(otherFiles)
    }

    if (filesToUpload.some((file) => !isFileValid(file))) {
      return
    }

    if (!orderId) {
      setErrorMessage(content.pages.orderDeliveryConfirmation.errors.missingOrderId)
      return
    }

    setIsSubmissionLoading(true)

    uploadFiles(
      {
        filesToUpload,
        containerId: orderId
      },
      {
        onSuccess: (data) => {
          const filesAlreadySubmittedToFilesApi: UploadedFileInfo[] = data.map((file) => {
            return file
          })

          const invoiceFileToSubmit = previouslyUploadedInvoiceFile
            ? previouslyUploadedInvoiceFile
            : filesAlreadySubmittedToFilesApi.find((x) => x.name === invoiceFile!.name)
          const otherFilesToSubmit = filesAlreadySubmittedToFilesApi.filter(
            (x) => x.fileId !== invoiceFileToSubmit!.fileId
          )

          submitEvidence(orderId, invoiceFileToSubmit!, otherFilesToSubmit, note, true)
        }
      }
    )
  }

  const handleRemoveOtherFile = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setOtherFiles([])
  }

  const handleRemoveInvoiceFile = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setInvoiceFile(null)
  }

  const handleAdditionalNoteChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAdditionalNoteCharacterCount(event.target.value)
  }

  useEffect(() => {
    if (deliveryConfirmationData && deliveryConfirmationData.invoiceFile) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setPreviouslyUploadedInvoiceFile(deliveryConfirmationData.invoiceFile)
    }

    if (deliveryConfirmationData && deliveryConfirmationData.otherFiles) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setPreviouslyUploadedOtherFiles(deliveryConfirmationData.otherFiles)
    }
  }, [deliveryConfirmationData])

  return (
    <div>
      <Link to={routes.orders}>
        <Stack marginBottom={theme.spacing(16)} direction={'row'}>
          <ArrowLeftIcon sx={{ color: 'black' }} />
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 400, textDecoration: 'underline', marginTop: theme.spacing(1) }}
          >
            List of orders
          </Typography>
        </Stack>
      </Link>
      <FormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DeliveryConfirmationTitleContainer>
            <Typography mb={theme.spacing(16)} variant="h1" data-cy="heading">
              {pageContent.title}
            </Typography>
            <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{pageContent.titleTooltip}</div>}>
              <InfoOutlined sx={{ fontSize: 15, marginLeft: theme.spacing(4) }} />
            </Tooltip>
          </DeliveryConfirmationTitleContainer>
          <Typography variant={'body1'} sx={{ marginBottom: theme.spacing(24) }}>
            {replaceString(pageContent.heading.description, pageContent.heading.linkTitle, () => (
              <a key="faq-link" href={pageContent.heading.href}>
                {pageContent.heading.linkTitle}
              </a>
            ))}
          </Typography>
          <Table sx={{ marginBottom: theme.spacing(32) }} data-cy="order-confirmation-summary-table">
            <TableHead sx={{ backgroundColor: theme.palette.common.white }}>
              <TableRow>
                <TableCell colSpan={2}>
                  <Typography variant={'h2'}>{pageContent.table.title}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableContent.map((r, index) => (
                <TableRow key={`table-row-${index}`}>
                  <TableCell>{r.title}</TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'right'
                    }}
                  >
                    {r.value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <DeliveryConfirmationUploadContainer>
            {!previouslyUploadedInvoiceFile && (
              <>
                <DeliveryConfirmationTitleContainer>
                  <Tag colorOptions={theme.palette.common.tags.mandatory} text={content.filesUpload.mandatory} />
                  <Typography variant="subtitle2" sx={{ marginLeft: theme.spacing(8), marginBottom: theme.spacing(8) }}>
                    {pageContent.files.invoice.title}
                  </Typography>
                </DeliveryConfirmationTitleContainer>
                <MainDiv>
                  <Container
                    {...getInvoiceRootProps({ isFocused: isInvoiceFocused, isDragActive: isInvoiceDragActive })}
                    data-cy="dropzone-container-invoice-file"
                  >
                    <input
                      {...register('invoiceFile')}
                      name="invoiceFile"
                      {...getInvoiceInputProps()}
                      data-cy="invoice-file-input"
                    />
                    <Typography variant="subtitle1" sx={{ fontWeight: 400, textAlign: 'center' }}>
                      {content.filesUpload.dropZoneTitle}{' '}
                      <span style={{ textDecoration: 'underline' }}>
                        {content.filesUpload.dropZoneTitleClickToUpload}
                      </span>
                      <br />
                      <em>
                        {content.filesUpload.dropZoneSupportedFileTypes}({acceptedInvoiceFileTypes})
                      </em>
                    </Typography>
                  </Container>
                </MainDiv>
              </>
            )}
            {!previouslyUploadedInvoiceFile && invoiceFile && (
              <TextField
                id="invoice-uploaded-file"
                InputProps={{
                  startAdornment: <FileCopyOutlinedIcon />,
                  endAdornment: (
                    <button
                      type="button"
                      onClick={handleRemoveInvoiceFile}
                      style={{ paddingTop: theme.spacing(3), background: 'none', border: 'none' }}
                      data-cy="delete-file-button"
                    >
                      <ClearOutlinedIcon fontSize="small" />
                    </button>
                  )
                }}
                value={invoiceFile?.name}
              ></TextField>
            )}

            {previouslyUploadedInvoiceFile && (
              <>
                <DeliveryConfirmationTitleContainer>
                  <Tag colorOptions={theme.palette.common.tags.mandatory} text={content.filesUpload.mandatory} />
                  <Typography variant="subtitle2" sx={{ marginLeft: theme.spacing(8), marginBottom: theme.spacing(8) }}>
                    {pageContent.files.invoice.title}
                  </Typography>
                </DeliveryConfirmationTitleContainer>
                <TextField id="invoice-uploaded-file" value={previouslyUploadedInvoiceFile?.name} disabled></TextField>
              </>
            )}
            <DropzoneRejectedFilesListComponent
              rejectedFiles={rejectedInvoiceFiles}
              acceptedFileTypes={acceptedInvoiceFileTypes}
            />
          </DeliveryConfirmationUploadContainer>

          <DeliveryConfirmationUploadContainer>
            <DeliveryConfirmationTitleContainer>
              <Tag colorOptions={theme.palette.common.tags.optional} text={content.filesUpload.optional} />
              <Typography variant="subtitle2" sx={{ marginLeft: theme.spacing(8), marginBottom: theme.spacing(8) }}>
                {pageContent.files.other.title}
              </Typography>
              <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{pageContent.files.other.tooltip}</div>}>
                <InfoOutlined sx={{ fontSize: 15, marginLeft: theme.spacing(4) }} />
              </Tooltip>
            </DeliveryConfirmationTitleContainer>
            <MainDiv>
              <Container
                {...getOtherRootProps({ isFocused: isOtherFocused, isDragActive: isOtherDragActive })}
                data-cy="dropzone-container-other-files"
              >
                <input {...register('otherFiles')} name="otherFiles" {...getOtherInputProps()} data-cy="file-input" />
                <Typography variant="subtitle1" sx={{ fontWeight: 400, textAlign: 'center' }}>
                  {content.filesUpload.dropZoneTitle}{' '}
                  <span style={{ textDecoration: 'underline' }}>{content.filesUpload.dropZoneTitleClickToUpload}</span>
                  <br />
                  <em>
                    {content.filesUpload.dropZoneSupportedFileTypes}({acceptedOtherFileTypes})
                  </em>
                </Typography>
              </Container>
            </MainDiv>
            {otherFiles.length > 0 && (
              <>
                {otherFiles?.map((file, i) => {
                  return (
                    <TextField
                      key={`delivery-confirmation-other-${i}`}
                      id="other-uploaded-file"
                      value={file?.name}
                      InputProps={{
                        startAdornment: <FileCopyOutlinedIcon />,
                        endAdornment: (
                          <button
                            type="button"
                            onClick={handleRemoveOtherFile}
                            style={{ paddingTop: theme.spacing(3), background: 'none', border: 'none' }}
                            data-cy="delete-file-button"
                          >
                            <ClearOutlinedIcon fontSize="small" />
                          </button>
                        )
                      }}
                    ></TextField>
                  )
                })}
              </>
            )}
            {previouslyUploadedOtherFiles && (
              <>
                {previouslyUploadedOtherFiles.map((file, i) => {
                  return (
                    <TextField
                      id="other-uploaded-file"
                      key={`other-uploaded-file-${i}`}
                      value={file?.name}
                      disabled
                    ></TextField>
                  )
                })}
              </>
            )}
            <DropzoneRejectedFilesListComponent
              rejectedFiles={rejectedOtherFiles}
              acceptedFileTypes={acceptedOtherFileTypes}
            />
          </DeliveryConfirmationUploadContainer>
          <DeliveryConfirmationUploadContainer>
            <DeliveryConfirmationTitleContainer>
              <Tag colorOptions={theme.palette.common.tags.optional} text={content.filesUpload.optional} />
              <Typography
                variant="subtitle2"
                sx={{ marginLeft: theme.spacing(8), marginBottom: theme.spacing(8), marginRight: theme.spacing(4) }}
              >
                {pageContent.files.additionalNote.title}
              </Typography>
            </DeliveryConfirmationTitleContainer>
            <MainDiv>
              <NoteTextField
                inputProps={{ maxLength: 500 }}
                id="other-uploaded-file"
                placeholder={content.pages.orderDeliveryConfirmation.placeholders.additionalNote}
                multiline={true}
                minRows={6}
                data-cy="note-input"
                {...register('note')}
                helperText={<CharacterCounter>{additionalNoteCharacterCount.length} / 500</CharacterCounter>}
                onChange={handleAdditionalNoteChange}
              ></NoteTextField>
            </MainDiv>
          </DeliveryConfirmationUploadContainer>
          <Button variant="contained" type={'submit'}>
            {isSubmissionLoading ? (
              <>
                {pageContent.submitButton.loadingTitle}
                <Box>
                  <Spinner></Spinner>
                </Box>
              </>
            ) : (
              pageContent.submitButton.title
            )}
          </Button>
          <Link style={{ marginLeft: theme.spacing(7) }} to={routes.orders}>
            <Button style={{ textDecoration: 'underline' }}>Cancel</Button>
          </Link>
          {errorMessage && <ErrorText id="file-upload">{errorMessage}</ErrorText>}
        </form>
      </FormContainer>
      <GenericFaqItems maxWidth={'100%'} content={content.pages.orderDeliveryConfirmation.faq} />
    </div>
  )
}

export default OrderDeliveryConfirmationPage
