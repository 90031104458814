import React from 'react'
import Typography from '@mui/material/Typography'
import content from '../../pages/content'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'

const PageLoading = () => {
  const theme = useTheme()
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '75vh'
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <CircularProgress
          variant="determinate"
          sx={{ color: theme.palette.common.backgroundGrey100 }}
          value={100}
          size={64}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          size={64}
          color="primary"
          sx={{ position: 'absolute', left: 0, animationDuration: '600ms' }}
        />
      </Box>
      <Typography marginTop="25px" variant="h1">
        {content.pages.loading.title}
      </Typography>
    </div>
  )
}

export default PageLoading
