import { AxiosError, AxiosInstance } from 'axios'

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error)
}

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error)
}

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(undefined, onRequestError)
  axiosInstance.interceptors.response.use(undefined, onResponseError)
  return axiosInstance
}
