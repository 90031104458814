import { apiClient } from '../api/apiClient'
import routes from '../routes'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

export const exportOrders = async (): Promise<number> => {
  const response = await apiClient.post(`orders/export`)
  return response.status
}

export const useExportOrders = ({
  onSuccess,
  onError
}: {
  onSuccess?: (data: number) => void
  onError?: () => void
}) => {
  const navigate = useNavigate()

  return useMutation(
    async (): Promise<number> => {
      return await exportOrders()
    },
    {
      onSuccess: (data) => {
        if (onSuccess) {
          onSuccess(data)
        }
      },
      onError: () => {
        if (onError) {
          onError()
        } else {
          navigate(routes.error)
        }
      }
    }
  )
}
