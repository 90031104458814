import { areEqualsUsingLocaleCompare } from '../utils/stringUtils'

export type BuyerListOnboardingStatusesProps = {
  [key: string]: {
    display: string
    value: string
  }
}

export const isOnboardingStatusNotStarted = (value?: string) =>
  areEqualsUsingLocaleCompare(buyerListOnboardingStatuses.NotStarted.value, value || '')

export const isOnboardingStatusInProgress = (value?: string) =>
  areEqualsUsingLocaleCompare(buyerListOnboardingStatuses.InProgress.value, value || '')

export const isOnboardingStatusManualReview = (value?: string) =>
  areEqualsUsingLocaleCompare(buyerListOnboardingStatuses.ManualReview.value, value || '')

export const isOnboardingStatusFailed = (value?: string) =>
  areEqualsUsingLocaleCompare(buyerListOnboardingStatuses.Failed.value, value || '')

export const isOnboardingStatusPassed = (value?: string) =>
  areEqualsUsingLocaleCompare(buyerListOnboardingStatuses.Passed.value, value || '')

export const buyerListOnboardingStatuses: BuyerListOnboardingStatusesProps = {
  NotStarted: {
    display: 'Not Started',
    value: 'NotStarted'
  },
  InProgress: {
    display: 'In Progress',
    value: 'InProgress'
  },
  ManualReview: {
    display: 'Manual Review',
    value: 'ManualReview'
  },
  Failed: {
    display: 'Failed',
    value: 'Failed'
  },
  Passed: {
    display: 'Passed',
    value: 'Passed'
  },
  NotAvailable: {
    display: 'N/A',
    value: 'NA'
  }
}
