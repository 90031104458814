import React, { useState } from 'react'
import StyledTable, { StyledTableProps } from '../../components/StyledTable'
import dayjs, { Dayjs } from 'dayjs'

import Box from '@mui/material/Box'
import Export from '../../components/export/Export'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { OrderDetails } from '../../types/orderDetails'
import OrderListFilters from './OrderListFilters'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { formatCurrency } from '../../utils/formatCurrency'
import { formatDate } from '../../utils/formatDate'
import theme from '../../assets/theme'
import { useOrderInfo } from '../../custom-hooks/useFetchOrdersQuery'
import { Link } from 'react-router-dom'
import { useExportOrders } from '../../custom-hooks/useExportOrders'
import content from '../content'

const OrdersPage = () => {
  const [keyword, setKeyword] = useState('')
  const [status, setStatus] = useState<string[]>([])
  const [paymentMethod, setPaymentMethod] = useState<string[]>([])
  const [dateFrom, setDateFrom] = useState<Dayjs | null>(null)
  const [dateTo, setDateTo] = useState<Dayjs | null>(null)

  const options: StyledTableProps<OrderDetails> = {
    columns: [
      {
        property: 'sourceCreatedDate',
        header: 'Created date',
        cell: (row) => formatDate(row.getValue<string>('sourceCreatedDate'))
      },
      {
        property: 'invoiceReferenceNumber',
        header: 'Invoice number',
        sortable: true
      },
      {
        property: 'marketplaceOrderId',
        header: 'Order number',
        headerTooltip: 'Even if the field is empty, it does not affect the order’s funding',
        sortable: false
      },
      {
        property: 'status',
        header: 'Status',
        sortable: true,
        cell: (row) => row.getValue<string>('statusDisplayName')
      },
      {
        property: 'isConfirmedStatus',
        header: 'Delivery confirmed',
        headerTooltip:
          "We will make every effort to expedite the advance payment process. For this purpose, our team needs to validate the order's legitimacy, which requires delivery confirmation.\n\nThe most reliable delivery confirmation comes from your customer's direct response to our email. Alternatively, you have the option to submit supporting documents to facilitate the order review.\n\nPlease use the link provided in the column to upload documents related to the respective order. Additional information about supporting documents can be found on the uploading page.",
        sortable: true,
        cell: (row) => {
          const { id, isConfirmedStatus, canUploadEvidence } = row.original
          return isConfirmedStatus ? (
            'Yes'
          ) : (
            <div>
              No
              {canUploadEvidence && (
                <>
                  &nbsp;- <Link to={`/orders/${id}/delivery-confirmation`}>Upload document</Link>
                </>
              )}
            </div>
          )
        }
      },
      {
        property: 'companyName',
        header: 'Customer name',
        sortable: true
      },
      {
        property: 'nationalIdentifiers',
        header: 'National ID',
        headerTooltip: 'The company’s registration number in the territory where it is incorporated',
        cell: (row) => (
          <Box
            sx={{
              display: 'flex',
              textAlign: 'center',
              gap: 3
            }}
          >
            {row.getValue<string[]>('nationalIdentifiers')[0]}
            {row.getValue<string[]>('nationalIdentifiers').length > 1 && (
              <Tooltip
                title={<>{row.getValue<string[]>('nationalIdentifiers').slice(1).join(', ')}</>}
                data-cy="national-identifiers"
              >
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            )}
          </Box>
        ),
        sortable: false
      },
      {
        property: 'amount',
        header: 'Invoice amount',
        cell: (row) => formatCurrency(row.getValue<number>('amount'), row.getValue<string>('currencyCode')),
        sortable: true
      },
      {
        property: 'internalFeeAmount',
        header: 'Kriya fee',
        cell: (row) => formatCurrency(row.getValue<number>('internalFeeAmount'), row.getValue<string>('currencyCode')),
        headerTooltip: 'The one-off fee is determined by your choice of payment method',
        sortable: false
      },
      {
        property: 'advanceAmount',
        header: 'Advance payment',
        cell: (row) => formatCurrency(row.getValue<number>('advanceAmount'), row.getValue<string>('currencyCode')),
        sortable: false
      },
      {
        property: 'paymentMethodDisplayName',
        header: 'Payment method',
        headerTooltip: `The payment method determines both the fee and due date:
      \u2022 PAY30 = Pay in 30 days
      \u2022 PAY60 = Pay in 60 days
      \u2022 PAY90 = Pay in 90 days
      \u2022 PAYEOM = Payment due at the end of month`,
        sortable: false
      },
      {
        property: 'advanceDate',
        header: 'Advanced date',
        cell: (row) => formatDate(row.getValue<string>('advanceDate')),
        sortable: true
      },
      {
        property: 'dueDate',
        header: 'Kriya due date',
        cell: (row) => formatDate(row.getValue<string>('dueDate')),
        sortable: true
      },
      {
        property: 'currencyCode',
        visible: false
      },
      {
        property: 'statusDisplayName',
        visible: false
      }
    ],
    fetchFn: useOrderInfo,
    sortingEnabled: true,
    defaultSorting: {
      name: 'sourceCreatedDate',
      desc: true
    },
    fetchOptions: {
      keyword: keyword,
      orderStatus: status,
      paymentMethod: paymentMethod,
      dateFrom: dateFrom !== null ? dayjs(dateFrom).format('YYYY-MM-DD') : null,
      dateTo: dateTo !== null ? dayjs(dateTo).format('YYYY-MM-DD') : null
    },
    dataCyAttributes: {
      table: 'orders-list-table',
      body: 'orders-list-table-body',
      head: 'orders-list-table-head'
    }
  }

  return (
    <>
      <Box sx={{ paddingBottom: theme.spacing(10) }}>
        <Typography variant="h1" data-cy="heading">
          Orders
        </Typography>

        <Box sx={{ paddingTop: theme.spacing(15) }}>
          <OrderListFilters
            setKeyword={setKeyword}
            status={status}
            setStatus={setStatus}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            dateFrom={dateFrom}
            dateTo={dateTo}
            setDateFrom={setDateFrom}
            setDateTo={setDateTo}
          />
        </Box>
        <Box sx={{ paddingTop: theme.spacing(6), textAlign: 'right', float: 'right' }}>
          <Export content={content.pages.orderList.export} mutation={useExportOrders} />
        </Box>
      </Box>
      <StyledTable {...options} />
    </>
  )
}

export default OrdersPage
