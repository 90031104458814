import { apiClient } from '../api/apiClient'
import { PaymentMethodsResponse } from '../types/PaymentMethodsResponse'

export const fetchPaymentMethods = async (amount: number, companyId: string): Promise<PaymentMethodsResponse[]> => {
  if (!companyId.length) return []

  const response = await apiClient.get<PaymentMethodsResponse[]>('/orders/paymentMethods', {
    params: {
      amount,
      companyId
    }
  })
  return response.data
}
