import { useQuery } from 'react-query'
import { retry } from '../api/apiBaseUrl'
import { apiClient } from '../api/apiClient'
import { SearchBuyersResponse } from '../types/SearchBuyersResponse'
import { BuyerAutocompleteDetails } from '../types/buyerAutocompleteDetails'

export const fetchCompanyAutocompleteDataKey = 'fetch-buyers-autocomplete-data'

export const fetchBuyersAutocompleteData = async (
  keyword: string | undefined,
  minLength: number
): Promise<BuyerAutocompleteDetails[]> => {
  if (!keyword || keyword.length <= minLength) return []

  const response = await apiClient.get<SearchBuyersResponse>('/buyers/autocomplete', {
    params: {
      keyword: keyword
    }
  })
  return response.data.items
}

export const useFetchBuyersAutocompleteData = (keyword: string | undefined, minLength: number) => {
  const { data, isLoading } = useQuery<BuyerAutocompleteDetails[], Error>(
    [fetchCompanyAutocompleteDataKey, keyword],
    () => fetchBuyersAutocompleteData(keyword, minLength),
    { retry, refetchOnWindowFocus: false }
  )

  return { data, isLoading }
}
