import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import theme from '../assets/theme'

type CardProps = {
  error?: string
  title?: string
  content?: string
  children?: React.ReactNode
}

const Card = ({ content, error, title, children }: CardProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: content ? theme.spacing(20) : theme.spacing(8)
      }}
    >
      {title && (
        <Typography variant="h3" data-cy="title">
          {title}
        </Typography>
      )}

      {error && (
        <Typography variant="h3" data-cy="error-text" color={theme.palette.error.main}>
          {error}
        </Typography>
      )}

      {content && (
        <Typography variant="body1" data-cy="content">
          {content}
        </Typography>
      )}
      {children}
    </Box>
  )
}

export default Card
