import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import theme from '../../assets/theme'
import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import SearchIcon from '@mui/icons-material/Search'
import TextField from '@mui/material/TextField'
import { useFetchBuyersAutocompleteData } from '../../custom-hooks/useFetchBuyersAutocompleteData'
import useDebounce from '../../custom-hooks/useDebounce'
import content from '../../pages/content'
import { BuyerAutocompleteDetails } from '../../types/buyerAutocompleteDetails'
import { SxProps, Theme } from '@mui/material/styles'

const componentContent = content.components.searchComponent

const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: BuyerAutocompleteDetails) => {
  const companyNumber = (option.nationalIdentifiers || []).join(', ')

  return (
    <Box component="li" {...props} key={companyNumber}>
      <Typography variant="inputText">{option.companyName}</Typography>
      <Typography
        variant="body2"
        sx={{
          marginLeft: theme.spacing(3)
        }}
      >
        {companyNumber}
      </Typography>
    </Box>
  )
}
type SearchProps = {
  defaultValue: BuyerAutocompleteDetails | null
  onValueSelected: (option: BuyerAutocompleteDetails | null) => void
  sx?: SxProps<Theme>
}
export const SearchComponent = ({ defaultValue, onValueSelected, sx }: SearchProps) => {
  const [autocompleteValue, setAutocompleteValue] = useState<BuyerAutocompleteDetails | null>(defaultValue)

  const [inputValue, setInputValue] = useState<string>(defaultValue?.companyName ?? '')

  const debouncedSearch = useDebounce(inputValue, 1000)
  const { isLoading, data } = useFetchBuyersAutocompleteData(debouncedSearch, 2)

  return (
    <Box
      sx={{
        p: {
          mobile: theme.spacing(7, 0, 0),
          xs: theme.spacing(6, 0, 0)
        },
        ...sx
      }}
    >
      <Autocomplete
        id="company-search-autocomplete"
        data-cy="company-search-autocomplete"
        value={autocompleteValue}
        filterOptions={(options) => options}
        isOptionEqualToValue={(option, value) => option.companyId === value.companyId}
        freeSolo={true}
        onChange={(_, value, reason) => {
          if (reason !== 'selectOption') {
            setAutocompleteValue(null)
            onValueSelected(null)
            return
          }
          if ((value as BuyerAutocompleteDetails) !== undefined) {
            const companyValue = value as BuyerAutocompleteDetails
            setAutocompleteValue(companyValue)
            onValueSelected(companyValue)
          }
        }}
        options={data || []}
        loading={isLoading}
        renderInput={(params) => (
          <TextField
            placeholder={componentContent.placeholder}
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: <SearchIcon />,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
        getOptionLabel={(option) =>
          (option as BuyerAutocompleteDetails) !== undefined
            ? (option as BuyerAutocompleteDetails).companyName || ''
            : (option as string)
        }
        onInputChange={(_event, value, reason) => {
          if (reason === 'reset') {
            return
          }
          setInputValue(value)
        }}
        renderOption={renderOption}
      />
    </Box>
  )
}
