import Box from '@mui/material/Box'
import NavigationButtons from './home-page/NavigationButtons'
import PageLoading from '../components/loading/PageLoading'
import React from 'react'
import Role from '../types/enums/Role'
import Typography from '@mui/material/Typography'
import theme from '../assets/theme'
import { useUserInfo } from '../custom-hooks/useFetchUserInfoQuery'
import GenericFaqItems from '../components/GenericFaqItems'
import content from './content'
import MerchantSpendingLimit from './home-page/MerchantSpendingLimit'

const HomePage = () => {
  const { data, isLoading } = useUserInfo()
  const isSeller = data != null && data.roles.indexOf(Role.Seller) >= 0

  if (!isLoading && data?.marketplace == null) {
    throw new Error('No marketplace information, this page should not be accessible')
  }

  return (
    <>
      {isLoading ? (
        <PageLoading />
      ) : (
        <>
          <Box sx={{ paddingBottom: theme.spacing(30) }}>
            <Typography variant="h1" data-cy="heading">
              {data?.marketplace?.name}
            </Typography>
          </Box>
          <MerchantSpendingLimit />
          <NavigationButtons />
          {isSeller && <GenericFaqItems content={content.pages.homePage.faq} maxWidth={'50%'} />}
        </>
      )}
    </>
  )
}

export default HomePage
