import { useQuery } from 'react-query'
import { apiClient } from '../api/apiClient'
import { retry } from '../api/apiBaseUrl'
import { UserDetails } from '../types/UserDetails'

export const fetchUserKey = 'fetch-user'

export const fetchUserDetails = async (): Promise<UserDetails> => {
  const response = await apiClient.get<UserDetails>('users/me', {
    validateStatus: function (status) {
      return status < 500
    }
  })
  return response.data
}

export const useUserInfo = () => {
  const { data, isLoading, error, isSuccess, refetch } = useQuery<UserDetails, Error>(
    [fetchUserKey],
    () => fetchUserDetails(),
    {
      retry,
      refetchOnWindowFocus: false
    }
  )

  return { data, isLoading, error, isSuccess, refetch }
}
