import React from 'react'
import Box from '@mui/material/Box'
import Spinner from './loading/Spinner'
import Button from '@mui/material/Button'
import { SxProps, Theme } from '@mui/material/styles'

type StyledButtonProps = {
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  id: string
  children?: React.ReactNode
  sx?: SxProps<Theme>
  isLoading?: boolean
  isLoadingContent?: string
}

const StyledButton = ({
  children,
  disabled,
  id,
  isLoading,
  isLoadingContent,
  onClick,
  sx,
  ...rest
}: StyledButtonProps) => {
  return (
    <Button variant="contained" disabled={disabled} id={id} type="submit" onClick={onClick} sx={sx} {...rest}>
      {isLoading ? (
        <>
          {isLoadingContent}
          <Box>
            <Spinner></Spinner>
          </Box>
        </>
      ) : (
        children
      )}
    </Button>
  )
}

export default StyledButton
