import { areEqualsUsingLocaleCompare } from './stringUtils'
import { BuyerKycStatus } from '../types/buyerKycStatus'

export const hasKycCheckResults = (value?: string) => !!value
export const isKycCheckPassed = (value?: string) => areEqualsUsingLocaleCompare(BuyerKycStatus.Passed, value || '')
export const isKycCheckFailed = (value?: string) => areEqualsUsingLocaleCompare(BuyerKycStatus.Failed, value || '')
export const isKycCheckInProgress = (value?: string) =>
  areEqualsUsingLocaleCompare(BuyerKycStatus.InProgress, value || '')
export const isKycCheckNotStarted = (value?: string) =>
  areEqualsUsingLocaleCompare(BuyerKycStatus.NotStarted, value || '')

export function getKycCheckStatusDescription(value?: string): string {
  switch (value) {
    case null:
    case undefined:
    case BuyerKycStatus.NotStarted:
      return 'Not started'
    case BuyerKycStatus.InProgress:
      return 'In progress'
    case BuyerKycStatus.Passed:
      return 'Approved'
    case BuyerKycStatus.Failed:
      return 'Rejected'
    default:
      return value
  }
}
