const routes = {
  root: '/',
  login: '/login',
  success: '/success',
  error: '/error',
  forbidden: '/forbidden',
  buyers: '/buyers',
  buyer: '/buyer/:companyId',
  orders: '/orders',
  createOrder: '/create-order',
  createOrderConfirmation: '/create-order/confirmation',
  createOrders: '/create-orders',
  createOrdersConfirmation: '/create-orders/confirmation',
  reportExpired: '/report-expired/:reportType?',
  orderDeliveryConfirmation: '/orders/:orderId/delivery-confirmation',
  customerOnboarding: '/customers/onboarding',
  customerOnboardingResults: '/customers/onboarding/results',
  customerOnboardingError: '/customers/onboarding/error',
  bulkCustomersOnboarding: '/customers/onboarding/bulk',
  payments: '/customers/payments',
  payment: '/customers/payments/:paymentId',
  impersonate: '/impersonate',
  notFound: '/not-found'
}

export default routes

export const externalRoutes = {
  officialWebsite: 'https://www.kriya.co/'
}
