import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React from 'react'
import content from '../content'

const pageContent = content.pages.createOrder.orderDetails

interface EditComponentProps {
  content: string
  handleDialogShow: () => void
}
const EditComponent = ({ content, handleDialogShow }: EditComponentProps) => (
  <>
    <Stack direction={'row'} sx={{ alignItems: 'center' }} justifyContent={'space-between'}>
      <Typography variant={'body1'}>{content}</Typography>
      <Typography
        variant={'body1'}
        onClick={handleDialogShow}
        sx={{
          cursor: 'pointer',
          textDecoration: 'underline'
        }}
      >
        {pageContent.edit.action}
      </Typography>
    </Stack>
  </>
)

export default EditComponent
