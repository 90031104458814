import React, { ReactElement, useEffect, useState } from 'react'
import { CreateOrderRequest } from '../../types/CreateOrderRequest'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { PaymentMethodsResponse } from '../../types/PaymentMethodsResponse'
import Radio from '@mui/material/Radio'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { UseFormReturn } from 'react-hook-form'
import content from '../content'
import { formatCurrency } from '../../utils/formatCurrency'
import { formatDate } from '../../utils/formatDate'
import { hasDifferentOrderCurrency } from '../../utils/multiCurrency'
import theme from '../../assets/theme'
import GenericPaymentSummary from '../../components/GenericPaymentSummary'

const pageContent = content.pages.createOrder.paymentDetails

interface PaymentDetailsProps {
  form: UseFormReturn<CreateOrderRequest>
  paymentMethods: PaymentMethodsResponse[]
  exchangeRate: number
}

const PaymentDetails = ({ form, paymentMethods, exchangeRate }: PaymentDetailsProps) => {
  const { setValue, getValues } = form
  const selectedOrderCurrency = form.getValues('currencyCode')
  const convertedAmount = Number(getValues('amount')) * Number(exchangeRate)

  const calculateExpectedSpendingLimit = () => {
    if (hasDifferentOrderCurrency(selectedOrderCurrency, selectedPaymentMethod.currencyCode)) {
      const finalAvailableLimit = Number(selectedPaymentMethod.spendingLimits.current) - convertedAmount

      return formatCurrency(finalAvailableLimit, selectedPaymentMethod.currencyCode)
    }
    return formatCurrency(selectedPaymentMethod.spendingLimits.expected, selectedPaymentMethod.currencyCode)
  }

  useEffect(() => {
    if (paymentMethods.length) {
      setValue('paymentMethod.name', paymentMethods[0].paymentMethod.code)
    }
  }, [paymentMethods, setValue])

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethodsResponse>(paymentMethods[0])
  const onRadioChanged = (value: string) => {
    setValue('paymentMethod.name', value)
    setSelectedPaymentMethod(paymentMethods.filter((r) => r.paymentMethod.code === value)[0])
  }

  const summaryContent: { title: ReactElement; value: ReactElement; withSpacing?: number }[] = [
    {
      title: <Typography variant={'h2'}>{pageContent.summary.amount}</Typography>,
      value: (
        <Typography variant={'h3'}>{formatCurrency(selectedPaymentMethod.amount, selectedOrderCurrency)}</Typography>
      )
    },
    {
      withSpacing: 6,
      title: (
        <Typography variant={'body1'}>
          {pageContent.summary.advanceRate} ({selectedPaymentMethod?.advanceRate || 0}%)
        </Typography>
      ),
      value: (
        <Typography variant={'body1'}>
          {formatCurrency(selectedPaymentMethod.advanceAmount, selectedOrderCurrency)}
        </Typography>
      )
    },
    {
      title: <Typography variant={'body1'}>{pageContent.summary.fee}</Typography>,
      value: (
        <Typography variant={'body1'}>
          -{formatCurrency(selectedPaymentMethod.fees.internalFeeAmount, selectedOrderCurrency)}
        </Typography>
      )
    },
    {
      title: <Typography variant={'body1'}>{pageContent.summary.advancePayment}</Typography>,
      value: (
        <Typography variant={'body1'}>
          {formatCurrency(selectedPaymentMethod.advancePayment, selectedOrderCurrency)}
        </Typography>
      )
    },
    ...(selectedPaymentMethod.advanceRate != 100
      ? [
          {
            title: (
              <Typography variant={'body1'}>
                {pageContent.summary.stubPayment.text}{' '}
                <Tooltip
                  title={<div style={{ whiteSpace: 'pre-line' }}>{pageContent.summary.stubPayment.tooltip}</div>}
                >
                  <InfoOutlinedIcon fontSize="small" sx={{ verticalAlign: 'middle', marginLeft: theme.spacing(2) }} />
                </Tooltip>
              </Typography>
            ),
            value: (
              <Typography variant={'body1'}>
                {formatCurrency(selectedPaymentMethod.stubPayment, selectedOrderCurrency)}
              </Typography>
            )
          }
        ]
      : []),
    {
      withSpacing: 6,
      title: <Typography variant={'h2'}>{pageContent.summary.currentSpendingLimit}</Typography>,
      value: (
        <Typography variant={'h2'}>
          {formatCurrency(selectedPaymentMethod.spendingLimits.current, selectedPaymentMethod.currencyCode)}
        </Typography>
      )
    },
    {
      title: <Typography variant={'h2'}>{pageContent.summary.expectedSpendingLimit}</Typography>,
      value: <Typography variant={'h2'}>{calculateExpectedSpendingLimit()}</Typography>
    }
  ]
  if (hasDifferentOrderCurrency(selectedOrderCurrency, selectedPaymentMethod.currencyCode)) {
    const amountConversionContent = {
      title: (
        <Typography variant="supplementaryText">
          {pageContent.summary.currencyConversion} {selectedPaymentMethod.currencyCode.toUpperCase()}
        </Typography>
      ),
      value: (
        <Typography variant="supplementaryText">
          {formatCurrency(convertedAmount, selectedPaymentMethod.currencyCode)}
        </Typography>
      )
    }
    summaryContent.splice(1, 0, amountConversionContent)
  }

  return (
    <div
      data-cy={'payment-methods-container'}
      style={{
        marginTop: theme.spacing(32)
      }}
    >
      <Typography variant={'h2'}>{pageContent.title} </Typography>
      <Typography sx={{ marginTop: theme.spacing(8) }} variant={'body1'}>
        {pageContent.heading}
      </Typography>

      <Table sx={{ marginTop: theme.spacing(8) }}>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>{pageContent.table.paymentMethod.text}</TableCell>
            <TableCell>
              {pageContent.table.fee.text}
              <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{pageContent.table.fee.tooltip}</div>}>
                <InfoOutlinedIcon fontSize="small" sx={{ verticalAlign: 'middle', marginLeft: theme.spacing(2) }} />
              </Tooltip>
            </TableCell>
            <TableCell>
              {pageContent.table.dueDate.text}
              <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{pageContent.table.dueDate.tooltip}</div>}>
                <InfoOutlinedIcon fontSize="small" sx={{ verticalAlign: 'middle', marginLeft: theme.spacing(2) }} />
              </Tooltip>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paymentMethods.map((pm) => (
            <TableRow key={pm.paymentMethod.code}>
              <TableCell>
                <Radio
                  name={'paymentMethod.name'}
                  value={pm.paymentMethod.code}
                  checked={getValues('paymentMethod.name') === pm.paymentMethod.code}
                  onChange={(value) => onRadioChanged(value.target.value)}
                />
              </TableCell>
              <TableCell>{pm.paymentMethod.description}</TableCell>
              <TableCell>
                {formatCurrency(pm.fees.internalFeeAmount, selectedOrderCurrency)} ({pm.fees.internalFeePercentage}
                %)
              </TableCell>
              <TableCell>{formatDate(pm.dueDate)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <GenericPaymentSummary
        title={pageContent.summary.title}
        content={summaryContent}
        exchangeRate={exchangeRate}
        orderCurrency={selectedOrderCurrency}
        companyCurrency={selectedPaymentMethod.currencyCode}
      />
    </div>
  )
}
export default PaymentDetails
