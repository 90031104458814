import { useMutation } from 'react-query'
import { apiClient } from '../api/apiClient'
import { useNavigate } from 'react-router-dom'
import routes from '../routes'
import { CreateOrderResponse } from '../types/createOrderResponse'
import { CreateOrderRequest } from '../types/CreateOrderRequest'
import { AxiosError, AxiosResponse } from 'axios'
import { IProblemDetails } from '../types/IProblemDetails'

export const createOrder = async (request: CreateOrderRequest): Promise<AxiosResponse<CreateOrderResponse>> => {
  return await apiClient.post<CreateOrderResponse>(`orders`, request)
}

export const useCreateOrder = ({
  onSuccess,
  onError
}: {
  onSuccess?: () => void
  onError?: (error: IProblemDetails | undefined) => void
}) => {
  const navigate = useNavigate()

  return useMutation(
    async (request: CreateOrderRequest): Promise<AxiosResponse<CreateOrderResponse>> => {
      return await createOrder(request)
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
      },
      onError: (error: AxiosError<IProblemDetails>) => {
        if (onError) {
          onError(error.response?.data)
        } else {
          navigate(routes.error)
        }
      }
    }
  )
}
