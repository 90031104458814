import { BuyerAutocompleteDetails } from '../types/buyerAutocompleteDetails'
import { isRiskApproved } from './riskDecisionUtils'
import { isKycCheckPassed } from './kycCheckUtils'
import { isOnboardingStatusPassed } from '../types/onboardingStatuses'

export const isApprovedCompany = (value: BuyerAutocompleteDetails | null) => {
  const hasLimit = (value?.availableSpendingLimit || 0) > 0
  let isApproved = isRiskApproved(value?.riskDecision)
  if (value?.isKycCheckRequired) {
    isApproved = isApproved && isKycCheckPassed(value?.buyerKycStatus)
  }
  return isApproved && hasLimit
}

export const isOrderingProcessForCompanyCanBeContinued = (
  value: BuyerAutocompleteDetails | null,
  isModularOnboardingEnabled: boolean
) => {
  if (!isModularOnboardingEnabled) {
    return value == null || isApprovedCompany(value)
  }
  return (
    value == null ||
    (value.areOnboardingChecksRequiredForLimitedCompanies === true &&
      isOnboardingStatusPassed(value.companyOnboardingStatus))
  )
}
