import React, { ReactElement } from 'react'
import content from '../../content'
import InformationBox, { InformationBoxRow } from '../../../components/informationBox/InformationBox'
import theme from '../../../assets/theme'
import TemplatedText from '../../../components/templatedText/TemplatedText'
import { Link } from 'react-router-dom'
import routes from '../../../routes'

const informationContent = content.pages.customerInformation.kycCheckInProgress

const KycCheckInProgressBox = () => {
  const replacements: { [key: string]: string | ReactElement } = {}
  replacements[informationContent.linkTitle] = (
    <Link to={routes.buyers} style={{ color: theme.palette.common.panels['warning'].color }}>
      {informationContent.linkTitle}
    </Link>
  )

  return (
    <InformationBox title={informationContent.title} type={'warning'}>
      <InformationBoxRow>
        <TemplatedText template={informationContent.description} replacements={replacements} />
      </InformationBoxRow>
    </InformationBox>
  )
}

export default KycCheckInProgressBox
