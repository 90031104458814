import LayoutContainer from './Container'
import Panel from './Panel'
import React from 'react'
import { Breakpoint } from '@mui/system'
import routes from '../../routes'
import theme from '../../assets/theme'
import { matchPath, useLocation } from 'react-router-dom'

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { pathname } = useLocation()
  const containerWidth: Record<Breakpoint, string[]> = {
    xs: [],
    sm: [routes.login, routes.success, routes.error, routes.forbidden, routes.notFound],
    md: [
      routes.createOrder,
      routes.createOrderConfirmation,
      routes.orderDeliveryConfirmation,
      routes.createOrders,
      routes.createOrdersConfirmation,
      routes.bulkCustomersOnboarding,
      routes.customerOnboarding,
      routes.customerOnboardingResults,
      routes.customerOnboardingError,
      routes.buyer,
      routes.payment
    ],
    lg: [routes.root, routes.impersonate],
    xl: []
  }

  const filtered = Object.fromEntries(
    Object.entries(containerWidth).filter(([_, v]) => v.filter((p) => matchPath(p, pathname)).length > 0)
  )

  const matchedSize = Object.keys(filtered)[0] as Breakpoint

  document.body.style.backgroundColor = matchedSize !== 'sm' ? theme.palette.common.white : '#F5F5F5'

  return (
    <LayoutContainer size={matchedSize}>
      <Panel size={matchedSize}>{children}</Panel>
    </LayoutContainer>
  )
}

export default Layout
