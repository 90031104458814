export const formatDate = (dateValue: string | null) => {
  if (!dateValue) {
    return null
  }

  const dateObject = new Date(dateValue)

  const day = dateObject.getDate()
  const month = dateObject.getMonth() + 1
  const year = dateObject.getFullYear()

  return `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`
}

export const formatDateTime = (dateValue: string | null) => {
  if (!dateValue) {
    return null
  }

  const dateObject = new Date(dateValue)

  const hour = dateObject.getHours()
  const minute = dateObject.getMinutes()

  return `${formatDate(dateValue)!} ${hour < 10 ? '0' : ''}${hour}:${minute < 10 ? '0' : ''}${minute}`
}
