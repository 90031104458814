export const formatCurrency = (amount: number, currencyCode?: string): string => {
  if (currencyCode) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode }).format(amount)
  }

  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount)
}

export const getCurrencySymbol = (currency: string, locale = 'en-US') =>
  (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
    .replace(/\d/g, '')
    .trim()

export const getAmountWithoutCurrency = (value: string) => {
  const numberRegex = new RegExp(
    `^(${getAvailableCurrencies()
      .map((r) => escapeRegExp(r))
      .join('|')})?(?<number>\\d*\\.?\\d*)$`,
    'gm'
  )
  const match = numberRegex.exec(value.replace(/\s+/g, ''))
  if (match === null) {
    return null
  }
  const matchedGroups = match.groups!
  const number = Number.parseFloat(matchedGroups['number'])
  if (Number.isNaN(number)) {
    return null
  }
  return evenRound(number, 2)
}

const escapeRegExp = (text: string) => {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
}

const getAvailableCurrencies = () => {
  if (typeof Intl.supportedValuesOf === 'function') {
    return Intl.supportedValuesOf('currency').map((r) => getCurrencySymbol(r))
  } else {
    return ['$', '€', '£']
  }
}

export const evenRound = (num: number, decimalPlaces: number) => {
  const d = decimalPlaces || 0
  const m = Math.pow(10, d)
  const n = +(d ? num * m : num).toFixed(8) // Avoid rounding errors
  const i = Math.floor(n),
    f = n - i
  const e = 1e-8 // Allow for rounding errors in f
  const r = f > 0.5 - e && f < 0.5 + e ? (i % 2 == 0 ? i : i + 1) : Math.round(n)
  return d ? r / m : r
}
