import { CurrencyConversionRates } from '../types/ExchangeRates'
import { apiClient } from '../api/apiClient'
import { retry } from '../api/apiBaseUrl'
import { useQuery } from 'react-query'

export const fetchExchangeRatesKey = 'fetch-exchange-rates'

export const fetchExchangeRates = async (): Promise<CurrencyConversionRates> => {
  const response = await apiClient.get<CurrencyConversionRates>('currentexchangerates')
  return response.data
}

export const useExchangeRatesInfo = () => {
  const { data, isLoading, status } = useQuery<CurrencyConversionRates, Error>(
    [fetchExchangeRatesKey],
    () => fetchExchangeRates(),
    {
      retry,
      refetchOnWindowFocus: false
    }
  )

  return { data, isLoading, status }
}
