import {
  ColumnDef,
  Getter,
  PaginationState,
  Row,
  SortingState,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import React, { ReactElement, useEffect, useRef } from 'react'
import { defaultPageIndex, defaultPageSize } from '../consts'

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import Box from '@mui/material/Box'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LinearProgress from '@mui/material/LinearProgress'
import { PaginatedResult } from '../types/PaginatedResult'
import Pagination from '@mui/material/Pagination'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import content from '../pages/content'
import { stringFormat } from '../utils/stringFormat'
import theme from '../assets/theme'
import ControlledHorizontalScrollbar from './ControlledHorizontalScrollbar'
import StickyBoxInternal from './StickyBox'

const componentContent = content.table

type Sorting = {
  name: string
  desc: boolean
}

export type StyledTableProps<TData> = {
  columns: {
    property: Extract<keyof TData, string>
    header?: string
    cell?: (row: Row<TData>, getValue: Getter<any>) => ReactElement | string | null
    headerTooltip?: string
    sortable?: boolean
    visible?: boolean
  }[]
  fetchFn: (options: PaginatedOptions) => {
    data: undefined | PaginatedResult<TData>
    isFetching: boolean
  }
  defaultSorting?: Sorting
  fetchOptions?: {}
  sortingEnabled?: boolean
  enableColumnResizing?: boolean
  dataCyAttributes?: {
    table?: string
    body?: string
    head?: string
  }
  headerFontVariant?: 'defaultTableHeader' | 'tableText'
}

export type PaginatedOptions = {
  pageIndex: number
  pageSize: number
  sortBy?: string
}

const StyledTable = <TData extends object>(props: StyledTableProps<TData>) => {
  const [sorting, setSorting] = React.useState<SortingState>(
    props.defaultSorting !== undefined
      ? [
          {
            id: props.defaultSorting.name,
            desc: props.defaultSorting.desc
          }
        ]
      : []
  )
  const ref = useRef<HTMLTableElement | null>(null)

  const columns = React.useMemo<ColumnDef<TData>[]>(
    () =>
      props.columns.map((r) => {
        return {
          accessorKey: r.property,
          header: r.header,
          cell: r?.cell === undefined ? (cell) => cell.renderValue() : ({ row, getValue }) => r.cell!(row, getValue),
          enableSorting: props.sortingEnabled === true && r.sortable,
          enableHiding: true,
          meta: r.headerTooltip
        }
      }),
    [props.sortingEnabled, props.columns]
  )

  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: defaultPageIndex,
    pageSize: defaultPageSize
  })

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  )

  const { data, isFetching } = props.fetchFn({
    ...props.fetchOptions,
    ...{
      sortBy: sorting.map((s) => `${s.desc ? '-' : ''}${s.id}`).join(','),
      pageSize,
      pageIndex
    }
  })

  const [totalItems, setTotalItems] = React.useState<number | undefined>()

  const table = useReactTable({
    data: data?.items ?? [],
    columns,
    pageCount: data?.totalPages ?? -1,
    state: {
      pagination,
      sorting
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onPaginationChange: setPagination,
    manualPagination: true,
    onSortingChange: setSorting,
    manualSorting: true,
    columnResizeMode: 'onChange',
    enableSortingRemoval: false
  })

  useEffect(() => {
    if (data !== undefined) {
      setTotalItems(data.totalCount)
    }
  }, [data])

  useEffect(() => {
    props.columns
      .filter((col) => col.visible === false)
      .forEach((col) => table.getColumn(col.property)?.toggleVisibility(col.visible))
  }, [table, props.columns])

  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize })
  }, [pageSize, props.fetchOptions])

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer
        ref={ref}
        component={Paper}
        sx={{
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'rgba(194, 194, 194, 1)',
          overflowX: 'hidden',
          borderBottomWidth: 0,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0
        }}
      >
        <Table data-cy={props.dataCyAttributes?.table}>
          <TableHead
            sx={{
              zIndex: 3,
              position: 'sticky',
              top: '0'
            }}
            data-cy={props.dataCyAttributes?.head}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableCell
                      key={header.id}
                      data-cy={`${header.id}-header`}
                      colSpan={header.colSpan}
                      sx={{
                        padding: '16px',
                        position: 'relative',
                        width: header.getSize(),
                        cursor: header.column.getCanSort() ? 'pointer' : '',
                        userSelect: 'none',
                        minWidth: '120px',
                        backgroundColor: '#FFF5E3',
                        lineHeight: '18px',
                        '&:hover .icon': {
                          display: 'block'
                        }
                      }}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        {header.isPlaceholder ? null : (
                          <Typography variant={props.headerFontVariant ?? 'defaultTableHeader'}>
                            {flexRender(header.column.columnDef.header, header.getContext())}
                          </Typography>
                        )}
                        {{
                          asc: <ArrowUpwardIcon fontSize="small" />,
                          desc: <ArrowDownwardIcon fontSize="small" />
                        }[header.column.getIsSorted() as string] ?? (
                          <Box
                            className="icon"
                            sx={{
                              display: 'none'
                            }}
                          >
                            {header.column.columnDef.enableSorting ? <ArrowUpwardIcon fontSize="small" /> : null}
                          </Box>
                        )}

                        {header.column.columnDef.meta && (
                          <Tooltip
                            title={
                              <div style={{ whiteSpace: 'pre-line' }}>{header.column.columnDef.meta as string}</div>
                            }
                            data-cy="header-tooltips"
                          >
                            <InfoOutlinedIcon fontSize="small" />
                          </Tooltip>
                        )}
                      </Stack>
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody data-cy={props.dataCyAttributes?.body}>
            {isFetching && (
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}
            {!isFetching &&
              table.getRowModel().rows.map((row) => {
                return (
                  <TableRow key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <TableCell key={cell.id} data-cy={`${cell.id}`}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}

            {!isFetching && totalItems === 0 && (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  sx={{
                    height: '200px',
                    textAlign: 'center'
                  }}
                >
                  <Typography variant={'h2'}>{componentContent.emptyResultsText}</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        component={StickyBoxInternal}
        sx={{
          zIndex: 3,
          backgroundColor: theme.palette.background.paper,
          borderColor: theme.palette.background.paper,
          width: '100%',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderTop: 0,
          borderBottom: 0
        }}
      >
        <ControlledHorizontalScrollbar
          targetRef={ref}
          sx={{
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'rgba(194, 194, 194, 1)',
            borderTop: 0,
            borderBottomRightRadius: theme.shape.borderRadius,
            borderBottomLeftRadius: theme.shape.borderRadius,
            marginLeft: '-1px'
          }}
        />
        {(totalItems ?? 0) > 0 && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            data-cy={`${props.dataCyAttributes?.table ?? ''}-pagination`}
            sx={{
              padding: theme.spacing(6, 0)
            }}
          >
            <Pagination
              count={data?.totalPages ?? 0}
              onChange={(_, page) => table.setPageIndex(page - 1)}
              page={pageIndex + 1}
              showFirstButton={true}
              showLastButton={true}
            />
            <Typography>
              {stringFormat(componentContent.paginationSummary, {
                from: pageIndex === 0 ? 1 : pageIndex * pageSize + 1,
                to: Math.min((pageIndex + 1) * pageSize, totalItems ?? 0),
                total: totalItems ?? 0
              })}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Box>
  )
}

export default StyledTable
