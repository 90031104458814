import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Link } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import React from 'react'
import Role from '../../types/enums/Role'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import content from '../content'
import theme from '../../assets/theme'
import { useUserInfo } from '../../custom-hooks/useFetchUserInfoQuery'

const componentContent = content.pages.homePage.actions

const NavigationButtons = () => {
  const { data, isLoading } = useUserInfo()

  const isButtonVisible = (role: Role | undefined): boolean => {
    return role === undefined || (data != null && data.roles.indexOf(role) >= 0)
  }

  if (isLoading) {
    return <Typography variant="body1">Loading.... </Typography>
  }

  return (
    <Box sx={{ paddingBottom: theme.spacing(30) }} data-cy={'action-items-container'}>
      <Typography
        variant="h1"
        sx={{
          paddingBottom: theme.spacing(15)
        }}
      >
        {componentContent.title}
      </Typography>
      <Grid container spacing={10}>
        {componentContent.buttons
          .filter((r) => isButtonVisible(r.requiredRole))
          .map((item, index) => (
            <Grid key={`button-link-${index}`} item xs={6} md={4}>
              <Link
                to={item.route}
                style={{
                  textDecoration: 'none'
                }}
              >
                <Paper
                  sx={{
                    height: 100,
                    padding: 7,
                    borderRadius: '8px',
                    border: '1px',
                    borderStyle: 'solid',
                    background: 'linear-gradient(0deg, #FFF5E3, #FFF5E3)'
                  }}
                >
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant={'h2'}>{item.title}</Typography>
                    <span>
                      <ArrowForwardIcon />
                    </span>
                  </Stack>
                  <Typography>{item.description}</Typography>
                </Paper>
              </Link>
            </Grid>
          ))}
      </Grid>
    </Box>
  )
}

export default NavigationButtons
