import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import { KriyaLogo } from '../../assets/storageAccountUploadLinks'
import Layout from './Layout'
import { Link } from 'react-router-dom'
import NavBarMenu from './NavBarMenu'
import React from 'react'
import Toolbar from '@mui/material/Toolbar'
import content from '../../pages/content'
import styled from '@emotion/styled'
import theme from '../../assets/theme'
import { useUserInfo } from '../../custom-hooks/useFetchUserInfoQuery'
import { ImpersonationBar } from './ImpersonationBar'

const componentContent = content.navBar

const StyledLogo = styled.img`
  height: 26px;
  width: 83px;
`
const NavigationBar = ({ children }: { children: React.ReactNode }) => {
  const { data } = useUserInfo()
  const isLoggedIn = data?.id != null
  const logoUrl = isLoggedIn ? componentContent.logo.url.loggedInUrl : componentContent.logo.url.loggedOutUrl

  return (
    <Box>
      <AppBar position="static" sx={{ backgroundColor: theme.palette.primary.main }}>
        <Container>
          <Toolbar disableGutters>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Link to={logoUrl}>
                <StyledLogo src={KriyaLogo} alt={componentContent.logo.alt} height="47px" width="157px" />
              </Link>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  gap: theme.spacing(10),
                  paddingLeft: theme.spacing(30)
                }}
              >
                {isLoggedIn &&
                  componentContent.navBarItems.map((item) => (
                    <NavBarMenu
                      key={item.title}
                      title={item.title}
                      childNavItems={item.children}
                      icon={<KeyboardArrowDown />}
                      roles={data.roles}
                    />
                  ))}
              </Box>
            </Box>
          </Toolbar>
        </Container>
        {data?.marketplace && <ImpersonationBar userInfo={data} />}
      </AppBar>
      <Layout>{children}</Layout>
    </Box>
  )
}

export default NavigationBar
