import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import { SxProps } from '@mui/material/styles'
import React, { useCallback, UIEvent, MutableRefObject, useState, useEffect } from 'react'

const Container = styled(Box)`
  width: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
`

const Expander = styled.div`
  height: 1px; // otherwise scroll won't work
  margin-bottom: -1px; // remove the above height
`

type ControlledHorizontalScrollbarProps = {
  targetRef: MutableRefObject<HTMLTableElement | null>
  sx: SxProps
}

const ControlledHorizontalScrollbar = ({ targetRef, sx }: ControlledHorizontalScrollbarProps) => {
  const [_, forceUpdate] = useState(false)

  useEffect(() => {
    const updateOnResize = () => forceUpdate((current) => !current)
    window.addEventListener('resize', updateOnResize)
    return () => {
      window.removeEventListener('resize', updateOnResize)
    }
  }, [targetRef])

  const onScroll = useCallback(
    (ev: UIEvent<HTMLDivElement>) => {
      if (targetRef?.current == null) {
        return
      }

      targetRef.current.scrollLeft = ev.currentTarget.scrollLeft
      ev.preventDefault()
    },
    [targetRef]
  )

  const targetWidth = targetRef.current?.clientWidth
  const targetContentWidth = targetRef.current?.scrollWidth
  if (targetWidth == null || targetContentWidth == null) {
    return null
  }

  const overflowing = targetWidth < targetContentWidth
  if (!overflowing) {
    return null
  }

  return (
    <Container className="KriyaHorizontalScrollbar-root" sx={sx} onScroll={onScroll}>
      <Expander className="KriyaHorizontalScrollbar-expander" style={{ width: targetContentWidth }} />
    </Container>
  )
}

export default ControlledHorizontalScrollbar
