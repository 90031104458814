import React from 'react'
import { Breakpoint } from '@mui/system'
import styled from '@emotion/styled'
import MuiContainer from '@mui/material/Container'

const Container = styled.div`
  min-height: 100vh;
  padding: 0 112px;
  position: relative;

  @media (max-width: 430px) {
    padding: 0 16px;
  }

  @media (max-width: 1024px) {
    padding: 0 32px;
  }

  @media (max-width: 1920px) {
    padding: 0 68px;
  }
`

const LayoutContainer = ({ children, size }: { children: React.ReactNode; size?: Breakpoint }) => {
  if (size != null) {
    return <MuiContainer maxWidth={size}>{children}</MuiContainer>
  }

  return <Container>{children}</Container>
}

export default LayoutContainer
