export type BuyerListRiskDecisionsProps = {
  [key: string]: {
    display: string
    value: string
  }
}

export const buyerListRiskDecisions: BuyerListRiskDecisionsProps = {
  All: {
    display: 'All',
    value: 'All'
  },
  Approved: {
    display: 'Approved',
    value: 'Approved'
  },
  Rejected: {
    display: 'Rejected',
    value: 'Rejected'
  },
  InReview: {
    display: 'In Review',
    value: 'InReview'
  },
  Submitted: {
    display: 'Submitted',
    value: 'Submitted'
  },
  OnHold: {
    display: 'On Hold',
    value: 'OnHold'
  }
}
