import Box from '@mui/material/Box'
import theme from '../../assets/theme'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { Panels } from '@mui/material/styles/createPalette'
import React, { useContext, useMemo } from 'react'

type InformationBoxContextState = {
  color: string
}

const InformationBoxContext = React.createContext<InformationBoxContextState | null>(null)

interface InformationBoxProps {
  title: string
  type: keyof Panels
  dataCy?: string
}

const InformationBox = ({ title, type, children, dataCy }: React.PropsWithChildren<InformationBoxProps>) => {
  const { color, background } = theme.palette.common.panels[type]

  const state = useMemo<InformationBoxContextState>(() => {
    return {
      color
    }
  }, [color])

  return (
    <InformationBoxContext.Provider value={state}>
      <Paper
        sx={{
          padding: theme.spacing(8, 12),
          borderRadius: '8px',
          border: '1px',
          borderStyle: 'solid',
          borderColor: color,
          background: background,
          color: color,
          maxWidth: '352px'
        }}
        data-cy={dataCy ?? 'company-information-box'}
      >
        <Box sx={{ display: 'grid', gap: theme.spacing(4) }}>
          <Typography component={'h2'} variant={'subtitle2'} sx={{ color: color }}>
            {title}
          </Typography>
          {children}
        </Box>
      </Paper>
    </InformationBoxContext.Provider>
  )
}

type InformationBoxRowProps = {
  color?: string
  [x: string]: any
}

export const InformationBoxRow = ({ children, color, ...rest }: React.PropsWithChildren<InformationBoxRowProps>) => {
  const context = useContext(InformationBoxContext)
  return (
    <Typography
      variant={'body2'}
      sx={{
        color: color || context?.color
      }}
      {...rest}
    >
      {children}
    </Typography>
  )
}

export default InformationBox
