import { useFlags } from '@unleash/proxy-client-react' // Assuming useFlags comes from here

interface IFeatureFlag {
  name: string
  enabled: boolean
}

export const useFeatureFlags = () => {
  const flagToggles = useFlags()
  return flagToggles.reduce((acc: { [key: string]: boolean }, flag: IFeatureFlag) => {
    acc[flag.name] = flag.enabled
    return acc
  }, {})
}
