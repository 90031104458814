import Typography from '@mui/material/Typography'
import theme from '../../assets/theme'
import ErrorText from '../input/FormErrors'
import React from 'react'
import { FileRejection } from 'react-dropzone'
import content from '../../pages/content'

export interface DropzoneRejectedFilesComponentProps {
  rejectedFiles: FileRejection[]
  acceptedFileTypes: string
}

export const DropzoneRejectedFilesListComponent = ({
  rejectedFiles,
  acceptedFileTypes
}: DropzoneRejectedFilesComponentProps) => {
  return (
    <>
      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        rejectedFiles.map(({ file: { path }, errors }) => (
          <div key={path}>
            <br />
            <>
              <Typography id="invalid-file-name" variant="helperText" color={theme.palette.error.main}>
                {path}
              </Typography>
              {errors.map((fileError) => {
                if (fileError.code === 'file-invalid-type') {
                  return (
                    <ErrorText id="invalid-file-type" key={fileError.code}>
                      {`${content.filesUpload.dropZoneInvalidFileType}${acceptedFileTypes}`}
                    </ErrorText>
                  )
                } else {
                  return (
                    <ErrorText id="other-file" key={fileError.code}>
                      {fileError.message}
                    </ErrorText>
                  )
                }
              })}
            </>
          </div>
        ))
      }
    </>
  )
}
