import React, { ReactNode } from 'react'
import { BuyerAutocompleteDetails } from '../../../types/buyerAutocompleteDetails'
import {
  isRiskApproved,
  isRiskInReview,
  isRiskOnHold,
  isRiskRejected,
  isRiskSubmitted
} from '../../../utils/riskDecisionUtils'
import RejectedBox from './RejectedBox'
import InReviewBox from './InReviewBox'
import BuyerInsufficientFundsBox from './BuyerInsufficientFundsBox'
import ValidBox from './ValidBox'
import {
  hasKycCheckResults,
  isKycCheckFailed,
  isKycCheckInProgress,
  isKycCheckNotStarted
} from '../../../utils/kycCheckUtils'
import KycCheckInProgressBox from './KycCheckInProgressBox'
import KycCheckNotStartedBox from './KycCheckNotStartedBox'
import { useFeatureFlags } from '../../../custom-hooks/useFeatureFlags'
import { ModularOnboarding } from '../../../unleash/unleashConfig'
import {
  isOnboardingStatusFailed,
  isOnboardingStatusInProgress,
  isOnboardingStatusManualReview,
  isOnboardingStatusNotStarted,
  isOnboardingStatusPassed
} from '../../../types/onboardingStatuses'
import OnboardingInProgressBox from './OnboardingInProgressBox'

type BuyerInformationBoxProps = {
  buyerCompany: BuyerAutocompleteDetails | null
}

const BuyerInformationBox = ({ buyerCompany }: BuyerInformationBoxProps): ReactNode => {
  const featureFlags = useFeatureFlags()

  const isModularOnboardingEnabled = featureFlags[ModularOnboarding]

  if (isModularOnboardingEnabled) {
    return HandleModularOnboardingInformationBox(buyerCompany)
  }

  if (
    isRiskRejected(buyerCompany?.riskDecision) ||
    (buyerCompany?.isKycCheckRequired && isKycCheckFailed(buyerCompany?.buyerKycStatus))
  ) {
    return <RejectedBox />
  }

  if (
    isRiskInReview(buyerCompany?.riskDecision) ||
    isRiskSubmitted(buyerCompany?.riskDecision) ||
    isRiskOnHold(buyerCompany?.riskDecision)
  ) {
    return <InReviewBox />
  }

  // Risk Approved cases
  if (buyerCompany?.isKycCheckRequired && isKycCheckNotStarted(buyerCompany?.buyerKycStatus)) {
    return <KycCheckNotStartedBox companyId={buyerCompany.companyId!} />
  }

  if (buyerCompany?.isKycCheckRequired && isKycCheckInProgress(buyerCompany?.buyerKycStatus)) {
    return <KycCheckInProgressBox />
  }

  if (buyerCompany?.availableSpendingLimit === 0) {
    return (
      <BuyerInsufficientFundsBox
        availableSpendingLimit={buyerCompany?.availableSpendingLimit}
        totalSpendingLimit={buyerCompany?.totalSpendingLimit}
        currency={buyerCompany?.currencyCode}
      />
    )
  }

  if (buyerCompany?.isKycCheckRequired && !hasKycCheckResults(buyerCompany?.buyerKycStatus)) {
    return <KycCheckNotStartedBox companyId={buyerCompany.companyId!} />
  }

  return (
    <ValidBox
      availableSpendingLimit={buyerCompany?.availableSpendingLimit}
      totalSpendingLimit={buyerCompany?.totalSpendingLimit}
      currency={buyerCompany?.currencyCode}
    />
  )
}

function HandleModularOnboardingInformationBox(buyerCompany: BuyerAutocompleteDetails | null) {
  if (
    isRiskRejected(buyerCompany?.riskDecision) ||
    (isRiskApproved(buyerCompany?.riskDecision) && isOnboardingStatusFailed(buyerCompany?.companyOnboardingStatus))
  ) {
    return <RejectedBox />
  }

  if (
    isRiskInReview(buyerCompany?.riskDecision) ||
    isRiskSubmitted(buyerCompany?.riskDecision) ||
    isRiskOnHold(buyerCompany?.riskDecision)
  ) {
    return <InReviewBox />
  }

  if (
    isRiskApproved(buyerCompany?.riskDecision) &&
    isOnboardingStatusPassed(buyerCompany?.companyOnboardingStatus) &&
    buyerCompany?.availableSpendingLimit === 0
  ) {
    return (
      <BuyerInsufficientFundsBox
        availableSpendingLimit={buyerCompany?.availableSpendingLimit}
        totalSpendingLimit={buyerCompany?.totalSpendingLimit}
        currency={buyerCompany?.currencyCode}
      />
    )
  }

  if (
    isRiskApproved(buyerCompany?.riskDecision) &&
    isOnboardingStatusNotStarted(buyerCompany?.companyOnboardingStatus)
  ) {
    return <KycCheckNotStartedBox companyId={buyerCompany!.companyId!} />
  }

  if (
    isRiskApproved(buyerCompany?.riskDecision) &&
    (isOnboardingStatusInProgress(buyerCompany?.companyOnboardingStatus) ||
      isOnboardingStatusManualReview(buyerCompany?.companyOnboardingStatus))
  ) {
    return <OnboardingInProgressBox />
  }

  return (
    <ValidBox
      availableSpendingLimit={buyerCompany?.availableSpendingLimit}
      totalSpendingLimit={buyerCompany?.totalSpendingLimit}
      currency={buyerCompany?.currencyCode}
    />
  )
}

export default BuyerInformationBox
