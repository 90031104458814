import React from 'react'
import content from '../../content'
import InformationBox, { InformationBoxRow } from '../../../components/informationBox/InformationBox'
import { formatCurrency } from '../../../utils/formatCurrency'
import { defaultCurrencyCode } from '../../../consts'
import theme from '../../../assets/theme'
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined'
const informationContent = content.pages.merchantInformation.merchantSpendingLimit

export type MerchantInsufficientFundsBoxProps = {
  assignedSpendingLimit: number
  availableSpendingLimit: number
  orderCurrency: string
  gbpExchangeRate: number
}
const MerchantInsufficientFundsBox = ({
  assignedSpendingLimit,
  availableSpendingLimit,
  orderCurrency,
  gbpExchangeRate
}: MerchantInsufficientFundsBoxProps) => {
  return (
    <InformationBox title={informationContent.title} type={'information'} dataCy="merchant-insufficient-funds-box">
      <InformationBoxRow>
        {informationContent.assignedLimit}: {formatCurrency(assignedSpendingLimit, defaultCurrencyCode)}
      </InformationBoxRow>
      <InformationBoxRow style={{ display: 'flex' }} color={theme.palette.common.panels.error.color}>
        <WarningOutlinedIcon
          sx={{
            verticalAlign: 'middle',
            paddingRight: theme.spacing(2)
          }}
        />
        <div>
          {informationContent.availableLimit}: {formatCurrency(availableSpendingLimit, defaultCurrencyCode)}
          {orderCurrency !== defaultCurrencyCode && (
            <div>
              ({formatCurrency(availableSpendingLimit * (1 / gbpExchangeRate), orderCurrency)} -{' '}
              {informationContent.description})
            </div>
          )}
          {orderCurrency === defaultCurrencyCode && <div>({informationContent.description})</div>}
        </div>
      </InformationBoxRow>
      <InformationBoxRow>{informationContent.message}</InformationBoxRow>
    </InformationBox>
  )
}

export default MerchantInsufficientFundsBox
