const defaultCountryCode = 'GB'
const defaultPageSize = 50
const defaultPageIndex = 0
const defaultCurrencyCode = 'GBP'
const dnbCountryRegistrationNumberType = 'CompaniesRegistryOfficeNumberGB'
const dunsNumberType = 'Duns'

const defaultMaxFileSizeInMb = 50
const deliveryConfirmationFileSize = defaultMaxFileSizeInMb
const dateFormat = 'DD/MM/YYYY'
const maximumNumberOfOrdersToCreate = 50
const onboardingAnalysisDelay = 2000
const availableCurrencies = [
  {
    value: 'GBP',
    label: 'GBP'
  },
  {
    value: 'USD',
    label: 'USD'
  },
  {
    value: 'EUR',
    label: 'EUR'
  }
]

export {
  defaultCountryCode,
  defaultPageSize,
  defaultPageIndex,
  dnbCountryRegistrationNumberType,
  dunsNumberType,
  defaultCurrencyCode,
  defaultMaxFileSizeInMb,
  deliveryConfirmationFileSize,
  dateFormat,
  availableCurrencies,
  maximumNumberOfOrdersToCreate,
  onboardingAnalysisDelay
}
