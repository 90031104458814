import React from 'react'
import content from './content'
import Card from '../components/PageCard'

const componentContent = content.pages.notFound

const NotFound = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <Card title={componentContent.title} />
    </div>
  )
}

export default NotFound
