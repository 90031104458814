import theme from '../../assets/theme'
import styled from '@emotion/styled'

type DropZoneContainerProps = {
  isFocused: boolean
  isDragActive: boolean
}

export const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
`
export const Container = styled.div<DropZoneContainerProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: 1px solid
    ${(props) =>
      props.isFocused || props.isDragActive
        ? theme.palette.common.backgroundActive
        : theme.palette.common.uploadBorderColor};
  background-color: ${theme.palette.common.grey10};
  outline: none;
  transition: border 0.24s ease-in-out;
  min-height: 165px;
  justify-content: center;
`
