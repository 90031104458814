import unleashApiKey from './unleashApiKeyMapping'

export const unleashConfig = {
  url: 'https://flags.kriya.co/api/frontend',
  clientKey: unleashApiKey(window.location.href),
  refreshInterval: 15,
  appName: 'Merchant Portal'
}

export const ModularOnboarding = 'EFM-7060_Release_Modular_Onboarding'
