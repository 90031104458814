import { defaultPageIndex, defaultPageSize } from '../consts'

import { ListOrderDetailsResponse } from '../types/listOrderDetailsResponse'
import { apiClient } from '../api/apiClient'
import { retry } from '../api/apiBaseUrl'
import { useQuery } from 'react-query'

export const fetchOrdersKey = 'fetch-orders'

export interface FetchOrderDetailsOptions {
  pageIndex: number
  pageSize: number
  sortBy?: string
  keyword?: string
  orderStatus?: string[]
  paymentMethod?: string[]
  dateFrom?: string
  dateTo?: string
}

export const fetchOrderDetails = async (
  options: FetchOrderDetailsOptions = {
    pageIndex: defaultPageIndex,
    pageSize: defaultPageSize
  }
): Promise<ListOrderDetailsResponse> => {
  const response = await apiClient.get<ListOrderDetailsResponse>('orders', {
    params: {
      keyword: options.keyword,
      pageIndex: options.pageIndex,
      pageSize: options.pageSize,
      sortBy: options.sortBy,
      orderStatus: options.orderStatus,
      paymentMethod: options.paymentMethod,
      dateFrom: options.dateFrom,
      dateTo: options.dateTo
    },
    paramsSerializer: { indexes: null }
  })

  return response.data
}

export const useOrderInfo = (
  options: FetchOrderDetailsOptions = {
    pageIndex: defaultPageIndex,
    pageSize: defaultPageSize
  }
) => {
  const { data, isLoading, error, isSuccess, isFetching } = useQuery<ListOrderDetailsResponse, Error>(
    [fetchOrdersKey, JSON.stringify(options)],
    () => fetchOrderDetails(options),
    {
      retry,
      refetchOnWindowFocus: false
    }
  )

  return { data, isLoading, error, isSuccess, isFetching }
}
