import { useQuery } from 'react-query'
import { apiClient } from '../api/apiClient'
import { retry } from '../api/apiBaseUrl'
import { DeliveryConfirmationResponse } from '../types/deliveryConfirmationResponse'

export const fetchDeliveryConfKey = 'fetch-delivery-confirmation'

export const fetchDeliveryConfirmation = async ({
  orderId
}: {
  orderId: string
}): Promise<DeliveryConfirmationResponse> => {
  const response = await apiClient.get<DeliveryConfirmationResponse>(`orders/${orderId}/deliveryEvidence/`, {
    validateStatus: function (status) {
      return status < 500
    }
  })
  return response.data
}

export const useFetchDeliveryConfirmation = ({ orderId }: { orderId?: string }) => {
  const { data, isLoading, error, isSuccess } = useQuery<DeliveryConfirmationResponse, Error>(
    [fetchDeliveryConfKey],
    () => fetchDeliveryConfirmation({ orderId: orderId! }),
    {
      retry,
      refetchOnWindowFocus: false,
      enabled: !!orderId
    }
  )

  return { data, isLoading, error, isSuccess }
}
