interface ISegment {
  page: (input: string) => void
  virtualPageView: (path: string) => void
}

interface ISegmentWindow extends Window {
  analytics: ISegment
  hj: (tag: string, path: string | string[]) => void
}

const isSegmentWindow = (win: Window): win is ISegmentWindow => {
  return (win as ISegmentWindow).analytics !== undefined
}

class SegmentWrapperImpl implements ISegment {
  private segmentWindow?: ISegmentWindow

  constructor() {
    this.segmentWindow = isSegmentWindow(window) ? window : undefined
  }

  page = (input: string): void => {
    this.segmentWindow?.analytics.page(input)
  }

  virtualPageView = (path: string): void => {
    if (typeof this.segmentWindow?.hj === 'function') {
      this.segmentWindow?.hj('vpv', `/${path}/`)
      this.segmentWindow?.hj('trigger', path)
      this.segmentWindow?.hj('tagRecording', [`/${path}/`])
    }
  }
}

const SegmentWrapper = new SegmentWrapperImpl()
export default SegmentWrapper
