import React from 'react'
import content from '../../content'
import InformationBox, { InformationBoxRow } from '../../../components/informationBox/InformationBox'
const informationContent = content.pages.customerInformation

const RejectedBox = () => {
  return (
    <InformationBox title={informationContent.rejected.title} type={'error'}>
      <InformationBoxRow>{informationContent.rejected.description}</InformationBoxRow>
    </InformationBox>
  )
}

export default RejectedBox
