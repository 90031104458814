import React from 'react'
import content from '../content'
import Typography from '@mui/material/Typography'
import { Link, Navigate, useLocation } from 'react-router-dom'
import { replaceString } from '../../utils/stringReplace'
import theme from '../../assets/theme'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import TableRow from '@mui/material/TableRow'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import { CreateOrderResponse } from '../../types/createOrderResponse'
import { formatCurrency } from '../../utils/formatCurrency'
import routes from '../../routes'
import dayjs from 'dayjs'
import Grid from '@mui/material/Grid'
import { dateFormat } from '../../consts'
import { DeliveryConfirmationResponse } from '../../types/deliveryConfirmationResponse'

const pageContent = content.pages.createOrderConfirmation

const CreateOrderConfirmationPage = () => {
  const location = useLocation()
  const orderResponse = location.state as CreateOrderResponse

  if (orderResponse == null) {
    return <Navigate to={routes.error} />
  }

  const tableContent = [
    {
      title: pageContent.table.customerName,
      value: orderResponse.companyName
    },
    {
      title: pageContent.table.nationalId,
      value: orderResponse.nationalId.join(', ')
    },
    {
      title: pageContent.table.invoiceReferenceNumber,
      value: orderResponse.invoiceReferenceNumber
    },
    {
      title: pageContent.table.amount,
      value: formatCurrency(orderResponse.amounts.amount, orderResponse.amounts.currency)
    },
    {
      title: pageContent.table.advancePayment,
      value: formatCurrency(orderResponse.amounts.advanceAmount, orderResponse.amounts.currency)
    },
    {
      title: pageContent.table.internalFee,
      value: formatCurrency(orderResponse.amounts.internalFeeAmount, orderResponse.amounts.currency)
    },
    {
      title: pageContent.table.internalFeePercentage,
      value: `${orderResponse.amounts.internalFeePercentage}%`
    },
    {
      title: pageContent.table.paymentMethod,
      value: orderResponse.paymentMethod.description
    },
    {
      title: pageContent.table.dueDate,
      value: orderResponse.dueDate ? dayjs(orderResponse.dueDate).format(dateFormat) : null
    },
    {
      title: pageContent.table.contactFullName,
      value: orderResponse.contactDetails.fullName
    },
    {
      title: pageContent.table.contactEmail,
      value: orderResponse.contactDetails.emailAddress
    },
    {
      title: pageContent.table.contactPhoneNumber,
      value: orderResponse.contactDetails.phoneNumber
    }
  ]

  return (
    <div>
      <Typography variant={'h1'} sx={{ marginBottom: theme.spacing(20) }}>
        {pageContent.title}
      </Typography>
      {pageContent.heading.map((r, index) => (
        <Typography variant={'body1'} key={`summary-heading-${index}`} sx={{ marginBottom: theme.spacing(15) }}>
          {r.description}
        </Typography>
      ))}
      <Stack direction="row" spacing={theme.spacing(6)} sx={{ marginBottom: theme.spacing(20) }}>
        <Link key="create-order-link" to={pageContent.actions.createOrder.route} data-cy="create-order-button">
          <Button variant="contained">{pageContent.actions.createOrder.title}</Button>
        </Link>

        <Link
          key="delivery-confirmation-link"
          to={`${routes.orders}/${orderResponse.orderId}/delivery-confirmation`}
          state={
            {
              orderId: orderResponse.orderId,
              companyName: orderResponse.companyName,
              invoiceReferenceNumber: orderResponse.invoiceReferenceNumber,
              amount: orderResponse.amounts.amount,
              currencyCode: orderResponse.amounts.currency,
              invoiceFile: undefined,
              otherFiles: undefined
            } as DeliveryConfirmationResponse
          }
          data-cy="delivery-confirmation-button"
        >
          <Button variant="outlined">{pageContent.actions.uploadEvidence.title}</Button>
        </Link>
      </Stack>
      <Table sx={{ marginBottom: theme.spacing(20) }} data-cy="order-confirmation-summary-table">
        <TableHead sx={{ backgroundColor: theme.palette.common.white }}>
          <TableRow>
            <TableCell colSpan={2} sx={{ padding: theme.spacing(4), paddingLeft: 0 }}>
              <Grid container>
                <Grid item xs={10}>
                  <Typography variant={'h2'}>{pageContent.table.title}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Link to={pageContent.actions.orderList.route} data-cy="order-list-button">
                    <Typography align="right">{pageContent.actions.orderList.title}</Typography>
                  </Link>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableContent.map((r, index) => (
            <TableRow key={`table-row-${index}`}>
              <TableCell sx={{ padding: theme.spacing(4), paddingLeft: 0 }}>
                <Typography variant={'body2'}>{r.title}</Typography>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: 'right',
                  padding: theme.spacing(4),
                  paddingRight: 0
                }}
              >
                <Typography variant={'body2'}>{r.value}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Typography variant={'body1'} key="summary-footer">
        {replaceString(pageContent.footer.description, pageContent.footer.linkTitle, () => (
          <Link key="homepage-link" to={pageContent.footer.href}>
            {pageContent.footer.linkTitle}
          </Link>
        ))}
      </Typography>
    </div>
  )
}

export default CreateOrderConfirmationPage
