import theme from '../../assets/theme'
import styled from '@emotion/styled'
import { Breakpoint } from '@mui/system'

type PanelProps = {
  size: Breakpoint
}

const Panel = styled.div<PanelProps>`
  background: ${(props) => (props.size !== 'sm' ? 'none' : theme.palette.common.white)};
  border-radius: 8px;
  margin: ${theme.spacing(12, 0, 0)};
  padding: ${theme.spacing(24)};
`

export default Panel
