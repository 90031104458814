import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Role from '../../types/enums/Role'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import styled from '@emotion/styled'
import { useTheme } from '@mui/material/styles'

interface NavBarMenuItem {
  title: string
  url: string
  requiredRole?: Role
}

interface NavBarMenuProps {
  title: string
  icon?: React.ReactNode
  childNavItems: NavBarMenuItem[]
  roles?: Role[]
}

interface StyledMenuProps {
  primaryColor: string
  bgColor: string
}

const StyledMenu = styled(Menu)<StyledMenuProps>`
  .MuiPaper-root {
    border: 2px solid ${(props) => props.primaryColor};
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    background: linear-gradient(0deg, ${(props) => props.bgColor}, ${(props) => props.bgColor});
    linear-gradient(0deg, ${(props) => props.primaryColor}, ${(props) => props.primaryColor});
  }
`

const NavBarMenu = ({ title, icon, childNavItems, roles }: NavBarMenuProps) => {
  const theme = useTheme()
  const [menuOpen, setMenuOpen] = useState<null | HTMLElement>(null)

  const filteredChildNavItems = childNavItems.filter((item) => {
    return !item.requiredRole || (roles && roles.includes(item.requiredRole))
  })

  const handleMenuClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setMenuOpen(event.currentTarget)
  }

  const handleMenuClose = () => {
    setMenuOpen(null)
  }

  return (
    <>
      <Button
        id={`navbar-menu-btn-${title}`}
        onClick={handleMenuClick}
        color="inherit"
        endIcon={icon}
        sx={{
          textTransform: 'none'
        }}
      >
        <Typography>{title}</Typography>
      </Button>
      <StyledMenu
        anchorEl={menuOpen}
        open={Boolean(menuOpen)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        primaryColor={theme.palette.common.primary25}
        bgColor={theme.palette.common.grey10}
      >
        {filteredChildNavItems.map((item, index) => {
          return (
            <MenuItem id={`navbar-menu-item-${item.title}`} key={item.title} onClick={handleMenuClose}>
              <Link to={item.url} style={{ textDecoration: 'none', color: 'inherit' }}>
                {item.title}
              </Link>
            </MenuItem>
          )
        })}
      </StyledMenu>
    </>
  )
}

export default NavBarMenu
