import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import theme from '../../assets/theme'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import React from 'react'
import content from '../content'

const pageContent = content.pages.createOrder.orderDetails

interface EditConfirmationDialogProps {
  handleDialogClose: () => void
  isDialogOpen: boolean
  onEdit: () => void
}
const EditConfirmationDialog = ({ onEdit, handleDialogClose, isDialogOpen }: EditConfirmationDialogProps) => (
  <Dialog
    onClose={handleDialogClose}
    open={isDialogOpen}
    role="dialog"
    aria-labelledby="edit-order-dialog-title"
    aria-describedby="edit-order-content"
    maxWidth={'xs'}
    data-cy={'edit-order-dialog'}
  >
    <DialogTitle id="edit-order-dialog-title" marginTop={theme.spacing(8)}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h1">{pageContent.edit.modal.title}</Typography>
        <div>
          <IconButton onClick={handleDialogClose} color="inherit">
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      </div>
    </DialogTitle>
    <DialogContent id="edit-order-content">
      <Typography
        variant="inputText"
        sx={{
          color: theme.palette.common.textGrey100
        }}
      >
        {pageContent.edit.modal.content}
      </Typography>
    </DialogContent>
    <DialogActions sx={{ margin: theme.spacing(0, 8, 8) }}>
      <Button variant="outlined" onClick={handleDialogClose} data-cy="cancel-button">
        <Typography variant="body2">{pageContent.edit.modal.actions.cancel}</Typography>
      </Button>
      <Button variant="contained" onClick={onEdit} data-cy="ok-button">
        <Typography variant="body2">{pageContent.edit.modal.actions.continue}</Typography>
      </Button>
    </DialogActions>
  </Dialog>
)

export default EditConfirmationDialog
