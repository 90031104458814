import React from 'react'
import content from './content'
import Card from '../components/PageCard'

const componentContent = content.pages.success

const Success = () => {
  return <Card title={componentContent.title} content={componentContent.description} />
}

export default Success
