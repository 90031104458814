import Paper from '@mui/material/Paper'
import React, { ReactElement } from 'react'
import theme from '../assets/theme'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { hasDifferentOrderCurrency } from '../utils/multiCurrency'
import Box from '@mui/system/Box'
import { formatCurrency } from '../utils/formatCurrency'
import content from '../pages/content'

const componentContent = content.components.genericPaymentSummary
export interface ContentItem {
  title: ReactElement
  value: ReactElement
  withSpacing?: number
}

export interface GenericPaymentSummaryProps {
  title: string
  orderCurrency: string
  companyCurrency: string
  exchangeRate: number
  content: ContentItem[]
  withSpacing?: number
}

const GenericPaymentSummary = ({
  title,
  orderCurrency,
  companyCurrency,
  exchangeRate,
  content,
  withSpacing
}: GenericPaymentSummaryProps) => {
  return (
    <Paper
      sx={{
        padding: theme.spacing(8),
        maxWidth: '466px',
        marginTop: theme.spacing(withSpacing || 10),
        backgroundColor: theme.palette.common.grey10
      }}
    >
      <Typography variant={'h3'} sx={{ marginBottom: theme.spacing(6) }}>
        {title}
      </Typography>
      {content.map((r, index) => (
        <Grid
          container
          key={`summary-row-${index}`}
          id={`summary-row-${index}`}
          sx={{ marginTop: r.withSpacing ? theme.spacing(r.withSpacing) : null }}
        >
          <Grid item md={8}>
            {r.title}
          </Grid>
          <Grid item md={4} sx={{ textAlign: 'right' }}>
            {r.value}
          </Grid>
        </Grid>
      ))}
      {hasDifferentOrderCurrency(orderCurrency, companyCurrency) && (
        <Box id="exchange-rate-message" sx={{ paddingTop: theme.spacing(12) }}>
          <Typography sx={{ marginTop: theme.spacing(8) }} variant="supplementaryText">
            {componentContent.orderExchangeRate} {formatCurrency(1, orderCurrency)} ={' '}
            {formatCurrency(exchangeRate, companyCurrency)} {componentContent.defaultExchangeRate}
          </Typography>
        </Box>
      )}
    </Paper>
  )
}

export default GenericPaymentSummary
