import { SxProps, SystemProps, createBox } from '@mui/system'
import {
  unstable_generateUtilityClasses as generateUtilityClasses,
  unstable_ClassNameGenerator as ClassNameGenerator
} from '@mui/utils'
import { THEME_ID, Theme, createTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import React from 'react'

const defaultTheme = createTheme()
const stickyBoxClasses = generateUtilityClasses('KriyaStickyBox', ['root'])

const StickyBoxInternal = createBox({
  themeId: THEME_ID,
  defaultTheme,
  defaultClassName: stickyBoxClasses.root,
  // eslint-disable-next-line @typescript-eslint/unbound-method
  generateClassName: ClassNameGenerator.generate
})

StickyBoxInternal.propTypes = {
  ...Box.propTypes
}

const StickyBox = ({
  stickTo,
  offset,
  sx,
  ...props
}: React.JSX.IntrinsicAttributes & { component: React.ElementType<any> } & SystemProps<Theme> & {
    children?: React.ReactNode
    ref?: React.Ref<unknown>
    stickTo?: 'top' | 'bottom'
    offset?: string | number
    sx: Omit<SxProps, 'top' | 'bottom' | 'position'>
  }) => {
  const combinedSx = {
    ...sx,
    position: 'sticky',
    [stickTo ?? 'bottom']: offset ?? 0
  }

  return <StickyBoxInternal sx={combinedSx} {...props} />
}
export default StickyBox
