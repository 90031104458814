export const stringFormat = (template: string, data: TemplateOption): string => {
  return Object.keys(data).reduce(
    // false-positive
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
    (acc: string, key: string): string => acc.replaceAll(`$\{${key}}`, data[key]?.toString()),
    template
  )
}

type TemplateOption = {
  [key: string]: string | number
}
