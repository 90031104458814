import React from 'react'
import Stack from '@mui/material/Stack'
import { SxProps, Theme } from '@mui/material/styles'
import StyledButtonAlone from './StyledButtonAlone'

type StyledContainedButtonProps = {
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  id: string
  children?: React.ReactNode
  sx?: SxProps<Theme>
  isLoading?: boolean
  isLoadingContent?: string
}

const StyledContainedButton = (props: StyledContainedButtonProps) => {
  return (
    <Stack direction="row">
      <StyledButtonAlone {...props} />
    </Stack>
  )
}

export default StyledContainedButton
