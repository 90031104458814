import React from 'react'
import content from '../../content'
import InformationBox, { InformationBoxRow } from '../../../components/informationBox/InformationBox'
import { formatCurrency } from '../../../utils/formatCurrency'
import { defaultCurrencyCode } from '../../../consts'
const informationContent = content.pages.customerInformation.valid

export type ValidBoxProps = {
  totalSpendingLimit?: number
  availableSpendingLimit?: number
  currency?: string
}

const ValidBox = ({ totalSpendingLimit, availableSpendingLimit, currency }: ValidBoxProps) => {
  const totalLimit = formatCurrency(totalSpendingLimit || 0, currency ?? defaultCurrencyCode)
  const availableLimit = formatCurrency(availableSpendingLimit || 0, currency ?? defaultCurrencyCode)

  return (
    <InformationBox title={informationContent.title} type={'information'}>
      <InformationBoxRow>
        {informationContent.description.totalSpendingLimit} {totalLimit}
      </InformationBoxRow>
      <InformationBoxRow>
        {informationContent.description.availableSpendingLimit} {availableLimit}
      </InformationBoxRow>
    </InformationBox>
  )
}

export default ValidBox
