import React from 'react'
import { useNavigate } from 'react-router-dom'
import Card from '../components/PageCard'
import content from './content'
import routes from '../routes'
import StyledContainedButton from '../components/StyledButton'

const componentContent = content.pages.error
const Error = () => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(routes.root)
  }

  return (
    <Card error={componentContent.title} content={componentContent.description}>
      <StyledContainedButton id="error-back-button" onClick={handleClick}>
        {componentContent.backButton.title}
      </StyledContainedButton>
    </Card>
  )
}

export default Error
