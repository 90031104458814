import { useNavigate, useParams } from 'react-router-dom'
import Card from '../components/PageCard'
import React from 'react'
import StyledContainedButton from '../components/StyledButton'
import content from './content'
import routes from '../routes'

const componentContent = content.pages.reportExpired

const routeMapping: { [key: string]: { route: string; description: string } } = {
  orders: { route: routes.orders, description: componentContent.orders.description },
  buyers: { route: routes.buyers, description: componentContent.buyers.description },
  payments: { route: routes.payments, description: componentContent.payments.description }
}

const ReportExpired = () => {
  const navigate = useNavigate()
  const { reportType } = useParams<{ reportType: string }>()
  let targetRoute = routes.root
  let description = componentContent.description

  if (reportType != null && routeMapping[reportType] != null) {
    targetRoute = routeMapping[reportType].route
    description = routeMapping[reportType].description
  }

  return (
    <Card error={componentContent.title} content={description}>
      <StyledContainedButton onClick={() => navigate(targetRoute)} id="back-to-button">
        {componentContent.backButton.title}
      </StyledContainedButton>
    </Card>
  )
}

export default ReportExpired
