import Box from '@mui/material/Box'
import React from 'react'
import Typography from '@mui/material/Typography'
import content from '../content'
import theme from '../../assets/theme'
import { useFetchMerchantSpendingLimit } from '../../custom-hooks/useFetchMerchantSpendingLimit'
import { formatCurrency } from '../../utils/formatCurrency'

const componentContent = content.pages.homePage.merchantSpendingLimit

const MerchantSpendingLimit = () => {
  const { data } = useFetchMerchantSpendingLimit()

  if (!data?.isHardLimit) {
    return <></>
  }

  return (
    <Box sx={{ paddingBottom: theme.spacing(30) }} data-cy={'merchant-spending-limit-container'}>
      <Box
        sx={{
          paddingBottom: theme.spacing(20)
        }}
      >
        <Typography
          variant="h1"
          sx={{
            paddingBottom: theme.spacing(10)
          }}
        >
          {componentContent.availableLimit}
        </Typography>
        <Typography fontSize={40} fontWeight={500}>
          {formatCurrency(data?.availableSpendingLimit ?? 0, data?.spendingLimitCurrency ?? 'GBP')}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Typography variant="body3" sx={{ whiteSpace: 'break-spaces' }}>
          {componentContent.assignedLimit}:{' '}
        </Typography>
        <Typography variant="body3" fontWeight={600}>
          {formatCurrency(data?.assignedSpendingLimit ?? 0)}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Typography variant="body3" sx={{ whiteSpace: 'break-spaces' }}>
          {componentContent.usedLimit}:{' '}
        </Typography>
        <Typography variant="body3" fontWeight={600}>
          {formatCurrency(data?.usedSpendingLimit ?? 0)}
        </Typography>
      </Box>
    </Box>
  )
}

export default MerchantSpendingLimit
