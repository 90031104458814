import React from 'react'
import content from '../../content'
import InformationBox, { InformationBoxRow } from '../../../components/informationBox/InformationBox'
import { formatCurrency } from '../../../utils/formatCurrency'
import { defaultCurrencyCode } from '../../../consts'
import theme from '../../../assets/theme'
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined'
const informationContent = content.pages.customerInformation.insufficientFunds

export type BuyerInsufficientFundsBoxProps = {
  totalSpendingLimit?: number
  availableSpendingLimit?: number
  currency?: string
}
const BuyerInsufficientFundsBox = ({
  totalSpendingLimit,
  availableSpendingLimit,
  currency
}: BuyerInsufficientFundsBoxProps) => {
  const totalLimit = formatCurrency(totalSpendingLimit || 0, currency ?? defaultCurrencyCode)
  const availableLimit = formatCurrency(availableSpendingLimit || 0, currency ?? defaultCurrencyCode)

  return (
    <InformationBox title={informationContent.title} type={'information'}>
      <InformationBoxRow>
        {informationContent.description.totalSpendingLimit} {totalLimit}
      </InformationBoxRow>
      <InformationBoxRow color={theme.palette.common.panels.error.color}>
        <WarningOutlinedIcon
          sx={{
            verticalAlign: 'middle',
            paddingRight: theme.spacing(2)
          }}
        />
        {informationContent.description.availableSpendingLimit} {availableLimit}
      </InformationBoxRow>
      <InformationBoxRow>{informationContent.description.needHelp}</InformationBoxRow>
    </InformationBox>
  )
}

export default BuyerInsufficientFundsBox
