import { useMutation } from 'react-query'
import { apiClient } from '../api/apiClient'
import { AxiosError, AxiosResponse } from 'axios'
import { UploadedFileInfo } from '../types/UploadedFileInfo'

export const deliveryConfirmationEvidenceSubmission = async (
  invoiceFileInfo: UploadedFileInfo,
  otherFilesInfo: UploadedFileInfo[],
  note: string,
  orderId: string
): Promise<AxiosResponse> => {
  const response = await apiClient.post(`/orders/${orderId}/deliveryEvidence`, {
    invoiceFile: invoiceFileInfo,
    otherFiles: otherFilesInfo,
    note
  })
  return response
}

export const useDeliveryConfirmationEvidenceSubmission = ({
  onSuccess,
  onError
}: {
  onSuccess?: () => void
  onError?: (error: AxiosError) => void
}) => {
  return useMutation(
    async ({
      invoiceFileInfo,
      otherFilesInfo,
      note,
      orderId
    }: {
      invoiceFileInfo: UploadedFileInfo
      otherFilesInfo: UploadedFileInfo[]
      note: string
      orderId: string
    }): Promise<AxiosResponse> => {
      return await deliveryConfirmationEvidenceSubmission(invoiceFileInfo, otherFilesInfo, note, orderId)
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
      },
      onError: (error: AxiosError) => {
        if (onError) {
          onError(error)
        }
      }
    }
  )
}
