import React, { ReactElement } from 'react'
import { replaceString } from '../../utils/stringReplace'

type TemplatedTextProps = {
  template: string
  replacements: { [key: string]: string | ReactElement }
}

const TemplatedText = ({ template, replacements }: TemplatedTextProps) => {
  let text = template
  Object.keys(replacements).forEach((key, index) => {
    if (text.indexOf(key) >= 0) {
      const replacement = replacements[key]
      if (typeof replacement === 'string') {
        text = text.replace(key, replacement)
      } else {
        text = replaceString(text, key, () => replacement)
      }
    }
  })

  return <>{text}</>
}

export default TemplatedText
