import React, { useState } from 'react'
import TextInput from './input/TextInput'
import { isEmailValid } from '../validation/emailValidation'
import { useRequestAccessInfo } from '../custom-hooks/useRequestAccess'
import StyledContainedButton from './StyledButton'
import content from '../pages/content'

const componentContent = content.pages.login

const EmailForm = () => {
  const [email, setEmail] = useState('')

  const requestUserAccessMutation = useRequestAccessInfo({
    email
  })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    requestUserAccessMutation.mutate()
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const isLoading = requestUserAccessMutation.isLoading

  return (
    <form onSubmit={handleSubmit} style={{ display: 'contents' }}>
      <TextInput name="email" label="Email address" onChange={handleChange} value={email} maxLength={150} />
      <StyledContainedButton
        disabled={!isEmailValid(email)}
        id="submit-email-button"
        isLoading={isLoading}
        isLoadingContent={componentContent.submitButton.loadingTitle}
      >
        {componentContent.submitButton.title}
      </StyledContainedButton>
    </form>
  )
}

export default EmailForm
