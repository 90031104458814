import React from 'react'
import theme from '../../assets/theme'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Typography from '@mui/material/Typography'

interface Props extends React.ComponentPropsWithoutRef<'input'> {
  name: string
  label: string
  type?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  value: string
  placeholder?: string
  additionalProps?: TextFieldProps
  children?: React.ReactNode
}

const TextInput: React.FC<Props> = ({ name, label, type, value, onChange, placeholder, additionalProps, children }) => {
  return (
    <FormControl>
      <FormLabel htmlFor={name}>
        <Typography variant="inputLabel" sx={{ color: theme.palette.common.inputText }}>
          {label}
        </Typography>
      </FormLabel>

      <TextField
        id={name}
        data-cy={name}
        type={type}
        value={value}
        onChange={onChange}
        {...additionalProps}
        placeholder={placeholder}
      >
        {children}
      </TextField>
    </FormControl>
  )
}

export default TextInput
